import { React } from "react";
import { Modal, Button } from "react-bootstrap";
import { useRecoilState } from "recoil";

export function Rules({ rulesState }) {
  // const seatToNames = useRecoilValue(seatToNamesState);
  const [rules, setRules] = useRecoilState(rulesState);

  return (
    <Modal
      // shouldCloseOnOverlayClick={false}
      animation={false}
      show={rules}
      id="rules"
      onHide={() => {
        setRules(false);
      }}
    >
      <Modal.Header>
        <Modal.Title> Rules of SpadesForHumanity</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
        //  style={{ paddingBottom: width }}
        ></div>
        <div
        // style={{ paddingBottom: width, fontSize: smallFond }}
        >
          <p>
            Rules align with{" "}
            <a
              href="https://en.wikipedia.org/wiki/Spades_(card_game)"
              target="_blank"
              rel="noopener noreferrer"
            >
              Spades (card game) on wikipedia
            </a>{" "}
            with a few variations:
          </p>
          <ul
          // style={{
          //   paddingLeft: width,
          //   paddingRight: width,
          //   paddingBottom: 0,
          // }}
          >
            <li>A 52 card deck is used.</li>
            <li>There's no passing cards.</li>
            <li>
              Scoring bids will consider the aggregate team bid and total team
              tricks.
            </li>
            <li>Nil is individual and is worth +/- 100 points.</li>
            <li>
              A player will be elgible to bid blid nil (+/- 200 points) if their
              team is behind 200 points.
            </li>
            <li>
              Win by reaching 500 points or sending your opponents to -500
              points.
            </li>
          </ul>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="submit"
          variant="secondary"
          className="mr-2"
          onClick={() => {
            // sendEmail(fromEmail + ":" + input);
            // setEmail(false);
            // handleCloseEmail();
            setRules(false);
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
