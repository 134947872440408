import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import { useRecoilState, useSetRecoilState } from "recoil";
import { useRecoilValue } from "recoil";

import "./style.css";

const OFF_WHITE = "#e6e6dd";

function Burger({ open, setOpen, width, buffer, pageHeight }) {
  let bdsWidth = open ? 1.18 * width : width;
  // bdsWidth = bdsWidth * 2;
  let burgerDivStyle = {
    width: `${bdsWidth}px`,
    height: `${bdsWidth / 7}px`,
    marginTop: `${width / 4}px`,
    background: "#b3ffb3",
    borderRadius: `${bdsWidth / 7}px`,
    transition: "all 0.3s linear",
    position: "relative",
    transformOrigin: "1px",
    zIndex: 2002,
  };

  return (
    <div
      open={open}
      onClick={() => setOpen(!open)}
      style={{
        left: `${pageHeight + 1.2 * buffer + 0.8 * buffer - width * 1.5}px`,
        position: "absolute",
        cursor: "pointer",
        zIndex: 2002,
        // backgroundColor: "red",
      }}
    >
      <div
        style={{
          zIndex: 2002,

          ...burgerDivStyle,
          transform: open ? "rotate(45deg)" : "rotate(0)",
        }}
      />
      <div
        style={{
          ...burgerDivStyle,
          opacity: open ? "0" : "1",
          transform: open ? `translateX(-${bdsWidth / 2}px)` : "translateX(0)",
        }}
      />
      <div
        style={{
          ...burgerDivStyle,
          transform: open ? "rotate(-45deg)" : "rotate(0)",
        }}
      />
      <div />
    </div>
  );
}

function OpenStuff({
  open,
  defaultWidth,
  overlapWidth,
  playerName,
  setPlayerName,
  setTargetpath,
  setEmailState,
  settingsState,
  rulesState,
  welcomeInfoState,
  pageHeight,
}) {
  let width = isMobile ? defaultWidth * 0.8 : defaultWidth;

  let smallFond = isMobile ? `${width * 0.6}px` : `${width * 0.6}px`;

  const [settings, setSettings] = useRecoilState(settingsState);
  const setRules = useSetRecoilState(rulesState);
  const setWelcomeInfo = useSetRecoilState(welcomeInfoState);

  // const [welcomeHover, setSettings] = useRecoilState(settingsState);

  let playerNameSet = playerName !== null && playerName !== "";
  const loadingColor = "#004500";

  // const defaultColor = "#b3ffb3";

  return (
    <div
      className="px-3"
      style={{
        transition: "all 0.3s linear",
        transform: open ? "translateX(0)" : `translateX(${overlapWidth}px)`,
        backgroundColor: loadingColor,
        // borderRadius: `${width}px 0px 0px ${width}px`,
        paddingTop: isMobile ? "0px" : `${2 * width}px`,
        color: OFF_WHITE,
        zIndex: 2001,
        pointerEvents: "auto",
        width: `${overlapWidth}px`,
        minHeight: `${pageHeight}px`,
        fontSize: smallFond,
        margin: playerNameSet ? "0px 0px 0px 5px" : "0px 0px 5px 5px",
        boxShadow: `0px 0px 3px 2px ${OFF_WHITE}`,
      }}
    >
      {playerNameSet && (
        <div
          style={{
            paddingLeft: "30px",
            paddingBottom: width,
            cursor: "default",
            userSelect: "none",
          }}
        >
          Hi <u>{playerName}</u>, thanks for playing spades!
        </div>
      )}

      {!playerNameSet && (
        <div
          style={{
            paddingLeft: "30px",
            paddingBottom: width,
            cursor: "default",
            userSelect: "none",
          }}
        >
          Thanks for playing spades!
        </div>
      )}

      <button
        className="menuButton"
        onClick={(e) => {
          setWelcomeInfo(true);
        }}
        style={{
          paddingLeft: "30px",
        }}
      >
        Welcome Info
      </button>

      <div style={{ paddingBottom: width }}></div>

      <button
        className="menuButton"
        onClick={(e) => {
          setSettings({ ...settings, show: true });
        }}
        style={{
          paddingLeft: "30px",
        }}
      >
        Settings
      </button>

      <div style={{ paddingBottom: width }}></div>

      <button
        className="menuButton"
        onClick={(e) => {
          setRules(true);
        }}
        style={{
          paddingLeft: "30px",
        }}
      >
        Rules
      </button>

      <div style={{ paddingBottom: width }}></div>

      <button
        className="menuButton"
        onClick={(e) => {
          setEmailState(true);
        }}
        style={{
          paddingLeft: "30px",
        }}
      >
        Contact
      </button>

      <div style={{ paddingBottom: width }}></div>

      <button
        className="menuButton"
        onClick={(e) => {
          setPlayerName("reload");
          setTargetpath("/");
        }}
        style={{
          paddingLeft: "30px",
        }}
      >
        Logout
      </button>

      {/* <div style={{ paddingBottom: width * 5 }}></div>
      <div
        style={{
          paddingLeft: "30px",
          paddingBottom: width,
          cursor: "default",
          userSelect: "none",
          bottom: "10px",
          position: "absolute",
        }}
      >
        <img
          valign="bottom"
          width="50"
          src="https://spadesforhumanity.com/spadefavicon.ico"
          alt="Loading SpadesForHumanity..."
        />
      </div> */}

      <div style={{ paddingBottom: width }}></div>
    </div>
  );
}

export function Menu({
  buffer,
  pageHeight,
  playerName,
  setPlayerName,
  setTargetpath,
  emailState,
  settingsState,
  rulesState,
  welcomeInfoState,
  seatToNamesState,
}) {
  const setEmailState = useSetRecoilState(emailState);
  const seatToNames = useRecoilValue(seatToNamesState);

  let width = pageHeight * 0.035;

  width = Math.max(width, 30);

  const [open, setOpen] = useState("");

  let openStuffWidth = 0.8 * buffer;
  openStuffWidth = Math.max(openStuffWidth, 200);

  return (
    <div id="menu">
      <div
        style={{
          // top right menu text
          // backgroundColor: "red",
          // height: `${pageHeight * 0.08}px`,
          width: `${buffer * 0.9}px`,
          left: `${pageHeight + buffer + 0.04 * buffer}px`,
          top: `${pageHeight * 0.02}px`,
          position: "absolute",
          zIndex: 160,
          color: "white",
          textAlign: "center",
          fontSize: `${0.028 * pageHeight}px`,
          fontWeight: "bold",
          cursor: "default",
          userSelect: "none",
        }}
      >
        {seatToNames && seatToNames.roomName}
        {/* Spades For Humanity */}
      </div>

      <div
        style={{
          // top right lines
          height: `${pageHeight * 0.0}px`,
          width: `${buffer * 0.9}px`,
          backgroundColor: "green",
          left: `${pageHeight + buffer + 0.04 * buffer}px`,
          top: `${pageHeight * 0.08}px`,
          position: "absolute",
          zIndex: 160,
          boxShadow: `0px 0px 2px 2px green`,
        }}
      />

      <div
        style={{
          // bottom right lines
          height: `${pageHeight * 0.0}px`,
          width: `${buffer * 0.9}px`,
          backgroundColor: "green",
          left: `${pageHeight + buffer + 0.04 * buffer}px`,
          top: `${pageHeight * 0.858}px`,
          position: "absolute",
          zIndex: 160,
          boxShadow: `0px 0px 2px 2px green`,
        }}
      />
      {/* 
      <div
        style={{
          // top left lines
          height: `${pageHeight * 0.0}px`,
          width: `${buffer * 0.9}px`,
          backgroundColor: "green",
          left: `${0.04 * buffer}px`,
          top: `${pageHeight * 0.15}px`,
          position: "absolute",
          zIndex: 9999999,
          boxShadow: `0px 0px 2px 2px green`,
        }}
      />

      <div
        style={{
          // bottom left

          height: `${pageHeight * 0.0}px`,
          width: `${buffer * 0.9}px`,
          backgroundColor: "green",
          left: `${0.04 * buffer}px`,
          top: `${pageHeight * 0.858}px`,
          position: "absolute",
          zIndex: 99,
          boxShadow: `0px 0px 2px 2px green`,
        }}
      /> */}

      <div>
        <Burger
          open={open}
          setOpen={setOpen}
          width={width}
          pageHeight={pageHeight}
          buffer={buffer}
        />
      </div>
      <div
        id="containerboxformenu"
        style={{
          position: "absolute",
          left: `${pageHeight + 2 * buffer - openStuffWidth}px`,
          height: open ? "auto" : `${pageHeight - 1}px`,
          // height: pageHeight * 0.5,
          // width: 0.8 * buffer,
          width: `${openStuffWidth}px`,
          // zIndex: open ? 1000000 : 0,
          // zIndex: 1,
          zIndex: 1010,
          tabIndex: "-1",
          // backgroundColor: "red",
          pointerEvents: "none",
          overflow: "hidden",
        }}
      >
        <OpenStuff
          open={open}
          overlapWidth={openStuffWidth}
          defaultWidth={width}
          playerName={playerName}
          setPlayerName={setPlayerName}
          setTargetpath={setTargetpath}
          setEmailState={setEmailState}
          settingsState={settingsState}
          rulesState={rulesState}
          welcomeInfoState={welcomeInfoState}
          pageHeight={pageHeight}
        ></OpenStuff>
      </div>
    </div>
  );
}
