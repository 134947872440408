import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useRecoilState } from "recoil";

export function Welcome({ welcomeInfoState }) {
  // const seatToNames = useRecoilValue(seatToNamesState);
  const [wecomeInfo, setWelcomeInfo] = useRecoilState(welcomeInfoState);

  return (
    <Modal
      // shouldCloseOnOverlayClick={false}
      animation={false}
      show={wecomeInfo}
      id="rules"
      onHide={() => {
        setWelcomeInfo(false);
      }}
    >
      <Modal.Header>
        <Modal.Title> Welcome to SpadesForHumanity</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Rooms</p>
        <ul>
          <li>
            Create a new room or join an existing room:
            wwww.spadesforhumaity.com/{"<"}roomName{">"}
          </li>
        </ul>

        <p>Computers</p>
        <ul>
          {/* <li>Click a seat then on cards to play.</li> */}
          <li>
            To add a computer, right click on desktop or click and hold on
            mobile.
          </li>
        </ul>

        <p>Privacy</p>
        <ul>
          {/* <li>Click a seat then on cards to play.</li> */}
          <li>Rooms allow a maximum of 4 players.</li>
        </ul>

        {/* 
        <p>Menu (top right corner)</p>
        <ul>
          <li>Lists rules of spades.</li>
          <li>Link to contact is you have questions.</li>
        </ul> */}

        {/* <p>Bugs</p>
        <ul>
          <li>To report bugs, go to Contact in Menu (upper right).</li>
        </ul> */}
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="submit"
          variant="secondary"
          className="mr-2"
          onClick={() => {
            // sendEmail(fromEmail + ":" + input);
            // setEmail(false);
            // handleCloseEmail();
            setWelcomeInfo(false);
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
