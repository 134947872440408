import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import Peer from "simple-peer";

export function StateManager({
  socket,
  stateState,
  needSyncState,
  messageState,
  seatToNamesState,
  videoSate0,
  videoSate1,
  videoSate2,
  videoSate3,
  dotsState,
  positionToVideoState,
  setErrorOccured,
  sendAudio,
  sendVideo,
  showSelf,
  allowPlayState,
}) {
  console.log("In state manager");
  const setState = useSetRecoilState(stateState);
  const setMessageState = useSetRecoilState(messageState);
  const setSeatToNames = useSetRecoilState(seatToNamesState);

  const setNeedSync = useSetRecoilState(needSyncState);

  const setVideo0 = useSetRecoilState(videoSate0);
  const setVideo1 = useSetRecoilState(videoSate1);
  const setVideo2 = useSetRecoilState(videoSate2);
  const setVideo3 = useSetRecoilState(videoSate3);

  const setDotsState = useSetRecoilState(dotsState);

  const setPositionToVideo = useSetRecoilState(positionToVideoState);

  const setAllowPlayState = useSetRecoilState(allowPlayState);

  const [myStream, setMyStream] = useState();

  useEffect(() => {
    if (socket != null) {
      console.log("Reload");

      socket.on("requestReload", (s) => {
        console.log("IN reload");
        console.log(s);
        window.location.reload();
      });
    } else {
      console.log("not setting up socket");
    }
  }, [socket]);

  // Set up streem
  useEffect(() => {
    let needSocket = sendVideo === true || sendAudio === true;

    let videoSettings = false;

    if (sendVideo === true) {
      videoSettings = {
        // width: "272",
        // height: "188",
        width: "544",
        height: "376",
      };
    }

    if (socket != null && needSocket) {
      console.log("setting up stream");
      navigator.mediaDevices
        .getUserMedia({
          audio: sendAudio,
          video: videoSettings,
        })
        .then(
          (stream) => {
            console.log("got user media");
            setMyStream(stream);
            socket.emit("streamCreated", {});
          },
          (err) => {
            console.log("The following error occurred: " + err.name);
            console.log(err);
            let msg =
              "Please ensure you have an allowed video and audio device that is not in use by another applications and try logging in again. Or disable audio and video in the settings.";
            setErrorOccured(msg);
          }
        );
    } else {
      console.log("not setting up socket");
    }
  }, [socket, sendVideo, sendAudio, setErrorOccured]);

  // Location 0
  useEffect(() => {
    if (socket != null && myStream != null && sendVideo === true) {
      console.log("Location 0 part one executing");
      socket.on("requestInit0", (s) => {
        if (s.from_index === s.to_index) {
          if (showSelf === true) {
            var videoOnlyStream = new MediaStream([
              myStream.getVideoTracks()[0],
            ]);
            setVideo0(videoOnlyStream);
          }
        } else {
          console.log("IN requestInit0");
          console.log(s);
          var senderPeer = new Peer({
            initiator: true,
            trickle: false,
            stream: myStream,
          });
          senderPeer.on("signal", function (senderData) {
            console.log("Seding");
            socket.emit("provideInit", { ...s, init: senderData });
          });
          socket.on("forwardResponse0", (s) => {
            console.log("IN forwardResponse0");
            console.log(s);
            senderPeer.signal(s.response);
          });
          senderPeer.on("stream", function (streamHere) {
            console.log("Set video 0");
            setVideo0(streamHere);
          });
        }
      });
    }
  }, [socket, myStream, setVideo0, sendVideo, showSelf]);
  useEffect(() => {
    if (socket != null && myStream != null && sendVideo === true) {
      socket.on("forwardedInit0", (s) => {
        console.log("IN forwardedInit");
        var recievePeer = new Peer({
          initiator: false,
          trickle: false,
          stream: myStream,
        });
        recievePeer.signal(s.init);
        recievePeer.on("signal", function (recieverData) {
          console.log("Seding provideResponse");
          socket.emit("provideResponse", {
            ...s,
            response: recieverData,
          });
        });
        recievePeer.on("stream", function (streamHere) {
          console.log("Set video 0");
          setVideo0(streamHere);
        });
      });
    }
  }, [socket, myStream, setVideo0, sendVideo]);

  // Location 1
  useEffect(() => {
    if (socket != null && myStream != null && sendVideo === true) {
      socket.on("requestInit1", (s) => {
        if (s.from_index === s.to_index) {
          if (showSelf === true) {
            var videoOnlyStream = new MediaStream([
              myStream.getVideoTracks()[0],
            ]);
            setVideo1(videoOnlyStream);
          }
        } else {
          console.log("IN requestInit1");
          console.log(s);
          var senderPeer = new Peer({
            initiator: true,
            trickle: false,
            stream: myStream,
          });
          senderPeer.on("signal", function (senderData) {
            console.log("Seding");
            socket.emit("provideInit", { ...s, init: senderData });
          });
          socket.on("forwardResponse1", (s) => {
            console.log("IN forwardResponse0");
            console.log(s);
            senderPeer.signal(s.response);
          });
          senderPeer.on("stream", function (streamHere) {
            console.log("Set video 1");
            // playAppear();
            setVideo1(streamHere);
          });
        }
      });
    }
  }, [socket, myStream, setVideo1, sendVideo, showSelf]);
  useEffect(() => {
    if (socket != null && myStream != null && sendVideo === true) {
      socket.on("forwardedInit1", (s) => {
        console.log("IN forwardedInit");
        var recievePeer = new Peer({
          initiator: false,
          trickle: false,
          stream: myStream,
        });
        recievePeer.signal(s.init);
        recievePeer.on("signal", function (recieverData) {
          console.log("Seding provideResponse");
          socket.emit("provideResponse", {
            ...s,
            response: recieverData,
          });
        });
        recievePeer.on("stream", function (streamHere) {
          console.log("Set video 1");
          setVideo1(streamHere);
        });
      });
    }
  }, [socket, myStream, setVideo1, sendVideo]);

  // Location 2
  useEffect(() => {
    if (socket != null && myStream != null && sendVideo === true) {
      socket.on("requestInit2", (s) => {
        if (s.from_index === s.to_index) {
          if (showSelf === true) {
            var videoOnlyStream = new MediaStream([
              myStream.getVideoTracks()[0],
            ]);
            setVideo2(videoOnlyStream);
          }
        } else {
          console.log("IN requestInit2");
          console.log(s);
          var senderPeer = new Peer({
            initiator: true,
            trickle: false,
            stream: myStream,
          });
          senderPeer.on("signal", function (senderData) {
            console.log("Seding");
            socket.emit("provideInit", { ...s, init: senderData });
          });
          socket.on("forwardResponse2", (s) => {
            console.log("IN forwardResponse0");
            console.log(s);
            senderPeer.signal(s.response);
          });
          senderPeer.on("stream", function (streamHere) {
            console.log("Set video 2");
            setVideo2(streamHere);
          });
        }
      });
    }
  }, [socket, myStream, setVideo2, sendVideo, showSelf]);
  useEffect(() => {
    if (socket != null && myStream != null && sendVideo === true) {
      socket.on("forwardedInit2", (s) => {
        console.log("IN forwardedInit 2");
        var recievePeer = new Peer({
          initiator: false,
          trickle: false,
          stream: myStream,
        });
        recievePeer.signal(s.init);
        recievePeer.on("signal", function (recieverData) {
          console.log("Seding provideResponse 2");
          socket.emit("provideResponse", {
            ...s,
            response: recieverData,
          });
        });
        recievePeer.on("stream", function (streamHere) {
          console.log("Set video 2");
          setVideo2(streamHere);
        });
      });
    }
  }, [socket, myStream, setVideo2, sendVideo]);

  // Location 3
  useEffect(() => {
    if (socket != null && myStream != null && sendVideo === true) {
      socket.on("requestInit3", (s) => {
        if (s.from_index === s.to_index) {
          if (showSelf === true) {
            var videoOnlyStream = new MediaStream([
              myStream.getVideoTracks()[0],
            ]);
            setVideo3(videoOnlyStream);
          }
        } else {
          console.log("IN requestInit3");
          console.log(s);
          var senderPeer = new Peer({
            initiator: true,
            trickle: false,
            stream: myStream,
          });
          senderPeer.on("signal", function (senderData) {
            console.log("Seding");
            socket.emit("provideInit", { ...s, init: senderData });
          });
          socket.on("forwardResponse3", (s) => {
            console.log("IN forwardResponse3");
            console.log(s);
            senderPeer.signal(s.response);
          });
          senderPeer.on("stream", function (streamHere) {
            console.log("Set video 3");
            setVideo3(streamHere);
          });
        }
      });
    }
  }, [socket, myStream, setVideo3, sendVideo, showSelf]);
  useEffect(() => {
    if (socket != null && myStream != null && sendVideo === true) {
      socket.on("forwardedInit3", (s) => {
        console.log("IN forwardedInit 3");
        var recievePeer = new Peer({
          initiator: false,
          trickle: false,
          stream: myStream,
        });
        recievePeer.signal(s.init);
        recievePeer.on("signal", function (recieverData) {
          console.log("Seding provideResponse 3");
          socket.emit("provideResponse", {
            ...s,
            response: recieverData,
          });
        });
        recievePeer.on("stream", function (streamHere) {
          console.log("Set video 3");
          setVideo3(streamHere);
        });
      });
    }
  }, [socket, myStream, setVideo3, sendVideo]);

  useEffect(() => {
    if (socket != null && setNeedSync != null && setState != null) {
      socket.on("state", (s) => {
        setState(s);
        setAllowPlayState(true);
        setNeedSync(true);
      });
    }
  }, [socket, setNeedSync, setAllowPlayState, setState]);

  useEffect(() => {
    if (socket != null && setMessageState != null) {
      socket.on("message", (s) => {
        console.log("On socket message");
        setMessageState(s.messages);
        console.log(s);
      });
    }
  }, [socket, setMessageState]);

  useEffect(() => {
    if (socket != null && setSeatToNames != null) {
      socket.on("seatToNames", (s) => {
        setSeatToNames(s.seatToNames);
      });
    }
  }, [socket, setSeatToNames]);

  useEffect(() => {
    if (socket != null && setDotsState != null) {
      socket.on("dotsState", (s) => {
        // console.log(s);
        setDotsState(s.dotsState);
      });
    }
  }, [socket, setDotsState]);

  useEffect(() => {
    if (socket != null && setPositionToVideo != null) {
      socket.on("positionToVideo", (s) => {
        // console.log("positionToVideo");
        // console.log(s);
        setPositionToVideo(s.positionToVideo);
      });
    }
  }, [socket, setPositionToVideo]);

  return <div></div>;
}

function compareCards(map1, map2) {
  if (map1.rank !== map2.rank) {
    return false;
  }

  if (map1.suit !== map2.suit) {
    return false;
  }

  if (map1.player !== map2.player) {
    return false;
  }
  if (map1.handPosition !== map2.handPosition) {
    return false;
  }
  if (map1.inPlay !== map2.inPlay) {
    return false;
  }

  if (map1.winningPlayer !== map2.winningPlayer) {
    return false;
  }
  if (map1.pilePosition !== map2.pilePosition) {
    return false;
  }

  if (map1.visible !== map2.visible) {
    return false;
  }

  if (map1.playable !== map2.playable) {
    return false;
  }

  if (map1.zIndex !== map2.zIndex) {
    return false;
  }

  if (map1.highlight !== map2.highlight) {
    return false;
  }

  return true;
}

export function CardStateHelper({
  stateState,
  needSyncState,
  // Card staes
  cardState0,
  cardState1,
  cardState2,
  cardState3,
  cardState4,
  cardState5,
  cardState6,
  cardState7,
  cardState8,
  cardState9,
  cardState10,
  cardState11,
  cardState12,
  cardState13,
  cardState14,
  cardState15,
  cardState16,
  cardState17,
  cardState18,
  cardState19,
  cardState20,
  cardState21,
  cardState22,
  cardState23,
  cardState24,
  cardState25,
  cardState26,
  cardState27,
  cardState28,
  cardState29,
  cardState30,
  cardState31,
  cardState32,
  cardState33,
  cardState34,
  cardState35,
  cardState36,
  cardState37,
  cardState38,
  cardState39,
  cardState40,
  cardState41,
  cardState42,
  cardState43,
  cardState44,
  cardState45,
  cardState46,
  cardState47,
  cardState48,
  cardState49,
  cardState50,
  cardState51,
}) {
  const state = useRecoilValue(stateState);
  const needSync = useRecoilValue(needSyncState);

  const [card0, setCard0] = useRecoilState(cardState0);
  const [card1, setCard1] = useRecoilState(cardState1);
  const [card2, setCard2] = useRecoilState(cardState2);
  const [card3, setCard3] = useRecoilState(cardState3);
  const [card4, setCard4] = useRecoilState(cardState4);
  const [card5, setCard5] = useRecoilState(cardState5);
  const [card6, setCard6] = useRecoilState(cardState6);
  const [card7, setCard7] = useRecoilState(cardState7);
  const [card8, setCard8] = useRecoilState(cardState8);
  const [card9, setCard9] = useRecoilState(cardState9);
  const [card10, setCard10] = useRecoilState(cardState10);
  const [card11, setCard11] = useRecoilState(cardState11);
  const [card12, setCard12] = useRecoilState(cardState12);
  const [card13, setCard13] = useRecoilState(cardState13);
  const [card14, setCard14] = useRecoilState(cardState14);
  const [card15, setCard15] = useRecoilState(cardState15);
  const [card16, setCard16] = useRecoilState(cardState16);
  const [card17, setCard17] = useRecoilState(cardState17);
  const [card18, setCard18] = useRecoilState(cardState18);
  const [card19, setCard19] = useRecoilState(cardState19);
  const [card20, setCard20] = useRecoilState(cardState20);
  const [card21, setCard21] = useRecoilState(cardState21);
  const [card22, setCard22] = useRecoilState(cardState22);
  const [card23, setCard23] = useRecoilState(cardState23);
  const [card24, setCard24] = useRecoilState(cardState24);
  const [card25, setCard25] = useRecoilState(cardState25);
  const [card26, setCard26] = useRecoilState(cardState26);
  const [card27, setCard27] = useRecoilState(cardState27);
  const [card28, setCard28] = useRecoilState(cardState28);
  const [card29, setCard29] = useRecoilState(cardState29);
  const [card30, setCard30] = useRecoilState(cardState30);
  const [card31, setCard31] = useRecoilState(cardState31);
  const [card32, setCard32] = useRecoilState(cardState32);
  const [card33, setCard33] = useRecoilState(cardState33);
  const [card34, setCard34] = useRecoilState(cardState34);
  const [card35, setCard35] = useRecoilState(cardState35);
  const [card36, setCard36] = useRecoilState(cardState36);
  const [card37, setCard37] = useRecoilState(cardState37);
  const [card38, setCard38] = useRecoilState(cardState38);
  const [card39, setCard39] = useRecoilState(cardState39);
  const [card40, setCard40] = useRecoilState(cardState40);
  const [card41, setCard41] = useRecoilState(cardState41);
  const [card42, setCard42] = useRecoilState(cardState42);
  const [card43, setCard43] = useRecoilState(cardState43);
  const [card44, setCard44] = useRecoilState(cardState44);
  const [card45, setCard45] = useRecoilState(cardState45);
  const [card46, setCard46] = useRecoilState(cardState46);
  const [card47, setCard47] = useRecoilState(cardState47);
  const [card48, setCard48] = useRecoilState(cardState48);
  const [card49, setCard49] = useRecoilState(cardState49);
  const [card50, setCard50] = useRecoilState(cardState50);
  const [card51, setCard51] = useRecoilState(cardState51);

  useEffect(() => {
    if (needSync && state.cards && !compareCards(state.cards[0], card0)) {
      setCard0(state.cards[0]);
    }
  });

  useEffect(() => {
    if (needSync && state.cards && !compareCards(state.cards[1], card1)) {
      setCard1(state.cards[1]);
    }
  });
  useEffect(() => {
    if (needSync && state.cards && !compareCards(state.cards[2], card2)) {
      setCard2(state.cards[2]);
    }
  });
  useEffect(() => {
    if (needSync && state.cards && !compareCards(state.cards[3], card3)) {
      setCard3(state.cards[3]);
    }
  });
  useEffect(() => {
    if (needSync && state.cards && !compareCards(state.cards[4], card4)) {
      setCard4(state.cards[4]);
    }
  });
  useEffect(() => {
    if (needSync && state.cards && !compareCards(state.cards[5], card5)) {
      setCard5(state.cards[5]);
    }
  });
  useEffect(() => {
    if (needSync && state.cards && !compareCards(state.cards[6], card6)) {
      setCard6(state.cards[6]);
    }
  });
  useEffect(() => {
    if (needSync && state.cards && !compareCards(state.cards[7], card7)) {
      setCard7(state.cards[7]);
    }
  });
  useEffect(() => {
    if (needSync && state.cards && !compareCards(state.cards[8], card8)) {
      setCard8(state.cards[8]);
    }
  });
  useEffect(() => {
    if (needSync && state.cards && !compareCards(state.cards[9], card9)) {
      setCard9(state.cards[9]);
    }
  });
  useEffect(() => {
    if (needSync && state.cards && !compareCards(state.cards[10], card10)) {
      setCard10(state.cards[10]);
    }
  });
  useEffect(() => {
    if (needSync && state.cards && !compareCards(state.cards[11], card11)) {
      setCard11(state.cards[11]);
    }
  });
  useEffect(() => {
    if (needSync && state.cards && !compareCards(state.cards[12], card12)) {
      setCard12(state.cards[12]);
    }
  });
  useEffect(() => {
    if (needSync && state.cards && !compareCards(state.cards[13], card13)) {
      setCard13(state.cards[13]);
    }
  });
  useEffect(() => {
    if (needSync && state.cards && !compareCards(state.cards[14], card14)) {
      setCard14(state.cards[14]);
    }
  });
  useEffect(() => {
    if (needSync && state.cards && !compareCards(state.cards[15], card15)) {
      setCard15(state.cards[15]);
    }
  });
  useEffect(() => {
    if (needSync && state.cards && !compareCards(state.cards[16], card16)) {
      setCard16(state.cards[16]);
    }
  });
  useEffect(() => {
    if (needSync && state.cards && !compareCards(state.cards[17], card17)) {
      setCard17(state.cards[17]);
    }
  });
  useEffect(() => {
    if (needSync && state.cards && !compareCards(state.cards[18], card18)) {
      setCard18(state.cards[18]);
    }
  });
  useEffect(() => {
    if (needSync && state.cards && !compareCards(state.cards[19], card19)) {
      setCard19(state.cards[19]);
    }
  });
  useEffect(() => {
    if (needSync && state.cards && !compareCards(state.cards[20], card20)) {
      setCard20(state.cards[20]);
    }
  });
  useEffect(() => {
    if (needSync && state.cards && !compareCards(state.cards[21], card21)) {
      setCard21(state.cards[21]);
    }
  });
  useEffect(() => {
    if (needSync && state.cards && !compareCards(state.cards[22], card22)) {
      setCard22(state.cards[22]);
    }
  });
  useEffect(() => {
    if (needSync && state.cards && !compareCards(state.cards[23], card23)) {
      setCard23(state.cards[23]);
    }
  });
  useEffect(() => {
    if (needSync && state.cards && !compareCards(state.cards[24], card24)) {
      setCard24(state.cards[24]);
    }
  });
  useEffect(() => {
    if (needSync && state.cards && !compareCards(state.cards[25], card25)) {
      setCard25(state.cards[25]);
    }
  });
  useEffect(() => {
    if (needSync && state.cards && !compareCards(state.cards[26], card26)) {
      setCard26(state.cards[26]);
    }
  });
  useEffect(() => {
    if (needSync && state.cards && !compareCards(state.cards[27], card27)) {
      setCard27(state.cards[27]);
    }
  });
  useEffect(() => {
    if (needSync && state.cards && !compareCards(state.cards[28], card28)) {
      setCard28(state.cards[28]);
    }
  });
  useEffect(() => {
    if (needSync && state.cards && !compareCards(state.cards[29], card29)) {
      setCard29(state.cards[29]);
    }
  });
  useEffect(() => {
    if (needSync && state.cards && !compareCards(state.cards[30], card30)) {
      setCard30(state.cards[30]);
    }
  });
  useEffect(() => {
    if (needSync && state.cards && !compareCards(state.cards[31], card31)) {
      setCard31(state.cards[31]);
    }
  });
  useEffect(() => {
    if (needSync && state.cards && !compareCards(state.cards[32], card32)) {
      setCard32(state.cards[32]);
    }
  });
  useEffect(() => {
    if (needSync && state.cards && !compareCards(state.cards[33], card33)) {
      setCard33(state.cards[33]);
    }
  });
  useEffect(() => {
    if (needSync && state.cards && !compareCards(state.cards[34], card34)) {
      setCard34(state.cards[34]);
    }
  });
  useEffect(() => {
    if (needSync && state.cards && !compareCards(state.cards[35], card35)) {
      setCard35(state.cards[35]);
    }
  });
  useEffect(() => {
    if (needSync && state.cards && !compareCards(state.cards[36], card36)) {
      setCard36(state.cards[36]);
    }
  });
  useEffect(() => {
    if (needSync && state.cards && !compareCards(state.cards[37], card37)) {
      setCard37(state.cards[37]);
    }
  });
  useEffect(() => {
    if (needSync && state.cards && !compareCards(state.cards[38], card38)) {
      setCard38(state.cards[38]);
    }
  });
  useEffect(() => {
    if (needSync && state.cards && !compareCards(state.cards[39], card39)) {
      setCard39(state.cards[39]);
    }
  });
  useEffect(() => {
    if (needSync && state.cards && !compareCards(state.cards[40], card40)) {
      setCard40(state.cards[40]);
    }
  });
  useEffect(() => {
    if (needSync && state.cards && !compareCards(state.cards[41], card41)) {
      setCard41(state.cards[41]);
    }
  });
  useEffect(() => {
    if (needSync && state.cards && !compareCards(state.cards[42], card42)) {
      setCard42(state.cards[42]);
    }
  });
  useEffect(() => {
    if (needSync && state.cards && !compareCards(state.cards[43], card43)) {
      setCard43(state.cards[43]);
    }
  });
  useEffect(() => {
    if (needSync && state.cards && !compareCards(state.cards[44], card44)) {
      setCard44(state.cards[44]);
    }
  });
  useEffect(() => {
    if (needSync && state.cards && !compareCards(state.cards[45], card45)) {
      setCard45(state.cards[45]);
    }
  });
  useEffect(() => {
    if (needSync && state.cards && !compareCards(state.cards[46], card46)) {
      setCard46(state.cards[46]);
    }
  });
  useEffect(() => {
    if (needSync && state.cards && !compareCards(state.cards[47], card47)) {
      setCard47(state.cards[47]);
    }
  });
  useEffect(() => {
    if (needSync && state.cards && !compareCards(state.cards[48], card48)) {
      setCard48(state.cards[48]);
    }
  });
  useEffect(() => {
    if (needSync && state.cards && !compareCards(state.cards[49], card49)) {
      setCard49(state.cards[49]);
    }
  });
  useEffect(() => {
    if (needSync && state.cards && !compareCards(state.cards[50], card50)) {
      setCard50(state.cards[50]);
    }
  });
  useEffect(() => {
    if (needSync && state.cards && !compareCards(state.cards[51], card51)) {
      setCard51(state.cards[51]);
    }
  });

  return <div></div>;
}
