import React, { useState } from "react";
import { animated, useSpring } from "react-spring";
import { useRecoilState, useSetRecoilState } from "recoil";

import s2 from "../images/s2.png";
import s3 from "../images/s3.png";
import s4 from "../images/s4.png";
import s5 from "../images/s5.png";
import s6 from "../images/s6.png";
import s7 from "../images/s7.png";
import s8 from "../images/s8.png";
import s9 from "../images/s9.png";
import s10 from "../images/s10.png";
import s11 from "../images/s11.png";
import s12 from "../images/s12.png";
import s13 from "../images/s13.png";
import s14 from "../images/s14.png";
import h2 from "../images/h2.png";
import h3 from "../images/h3.png";
import h4 from "../images/h4.png";
import h5 from "../images/h5.png";
import h6 from "../images/h6.png";
import h7 from "../images/h7.png";
import h8 from "../images/h8.png";
import h9 from "../images/h9.png";
import h10 from "../images/h10.png";
import h11 from "../images/h11.png";
import h12 from "../images/h12.png";
import h13 from "../images/h13.png";
import h14 from "../images/h14.png";
import d2 from "../images/d2.png";
import d3 from "../images/d3.png";
import d4 from "../images/d4.png";
import d5 from "../images/d5.png";
import d6 from "../images/d6.png";
import d7 from "../images/d7.png";
import d8 from "../images/d8.png";
import d9 from "../images/d9.png";
import d10 from "../images/d10.png";
import d11 from "../images/d11.png";
import d12 from "../images/d12.png";
import d13 from "../images/d13.png";
import d14 from "../images/d14.png";
import c2 from "../images/c2.png";
import c3 from "../images/c3.png";
import c4 from "../images/c4.png";
import c5 from "../images/c5.png";
import c6 from "../images/c6.png";
import c7 from "../images/c7.png";
import c8 from "../images/c8.png";
import c9 from "../images/c9.png";
import c10 from "../images/c10.png";
import c11 from "../images/c11.png";
import c12 from "../images/c12.png";
import c13 from "../images/c13.png";
import c14 from "../images/c14.png";
import back from "../images/back.png";

export function Card({
  cardState,
  pageHeight,
  buffer,
  needSyncState,
  playCard,
  allowPlayState,
}) {
  const setNeedSync = useSetRecoilState(needSyncState);

  const [card, setCard] = useRecoilState(cardState);
  const [alllowPlay, setAllowPlay] = useRecoilState(allowPlayState);

  const [down, setDown] = useState(false);

  let trueWidth = pageHeight;
  let width = Math.round(trueWidth);

  // let buffer = Math.round((trueWidth - width) / 2);
  // buffer = 100;

  let increment = Math.round(width / 16);
  let cardWidth = 2 * increment;
  let cardHeigh = Math.round((303 / 208) * cardWidth);

  let center = Math.round(width / 2);

  // Shifts everythig down
  let shiftDown = cardWidth / 10;

  let inPlayShiftUp = -cardWidth * 0.5;
  // inPlayShiftUp = 0;

  let scaleForOther = 0.6;
  let otherWidth = Math.round(width * scaleForOther);
  let otherIncrement = Math.round(increment * scaleForOther);
  let epsilon = Math.round(cardWidth / 1.5);

  // if player is 0
  let scale = 1;
  let x = buffer + increment + increment * card.handPosition;
  let y = center * 1.5 + Math.abs(6 - card.handPosition) ** 2 * 0.5;
  let rotation = 0;

  rotation =
    (card.handPosition - 6) * 0.8 * Math.abs(6 - card.handPosition) ** 0.0;

  if (card.inPlay === true) {
    scale = 0.8;
    x = buffer + center - cardWidth / 2;
    y = center - cardHeigh / 2 + epsilon + inPlayShiftUp;
    rotation = 360;
  }

  if (card.player === 3) {
    if (card.inPlay === true) {
      scale = 0.8;
      x = buffer + center - cardWidth / 2 + epsilon;
      y = center - cardHeigh / 2 + inPlayShiftUp;
    } else {
      scale = scaleForOther;
      x = buffer + 2 * center - cardWidth - shiftDown;
      y = otherIncrement * card.handPosition + shiftDown * 2;
      rotation = 0;
    }
  }
  if (card.player === 2) {
    if (card.inPlay === true) {
      scale = 0.8;
      x = buffer + center - cardWidth / 2;
      y = center - cardHeigh / 2 - epsilon + inPlayShiftUp;
    } else {
      scale = scaleForOther;
      x =
        buffer +
        Math.round((width - otherWidth) / 2) +
        otherIncrement * card.handPosition;
      y = 0 + shiftDown * 2;
      rotation = 0;
    }
  }
  if (card.player === 1) {
    if (card.inPlay === true) {
      scale = 0.8;
      x = buffer + center - cardWidth / 2 - epsilon;
      y = center - cardHeigh / 2 + inPlayShiftUp;
    } else {
      scale = scaleForOther;
      x = buffer + 0 + shiftDown;
      y = otherIncrement * card.handPosition + shiftDown * 2;
      rotation = 0;
    }
  }

  let adjustedOffset = card.pilePosition + 5;
  let ySecondayOffsetInPiles = (otherIncrement / 2) * card.pilePosition;
  let xSecondayOffsetInPiles = (increment / 2) * card.pilePosition;
  if (card.winningPlayer === 0) {
    scale = 0.5;
    x = buffer + increment + (increment * adjustedOffset) / 2;
    y = center * 1.5 - cardHeigh * 0.9 - ySecondayOffsetInPiles - shiftDown;
    rotation = 0;
  }
  if (card.winningPlayer === 1) {
    scale = 0.5;
    x = buffer + cardWidth / 1.5 + xSecondayOffsetInPiles + shiftDown;
    y = otherIncrement * adjustedOffset;
    rotation = 0;
  }
  if (card.winningPlayer === 2) {
    scale = 0.5;
    x =
      buffer +
      Math.round((width - otherWidth) / 2) +
      otherIncrement * adjustedOffset;
    y = 0 + cardHeigh * 0.7 + ySecondayOffsetInPiles + shiftDown;
    rotation = 0;
  }
  if (card.winningPlayer === 3) {
    scale = 0.5;
    x =
      buffer +
      2 * center -
      cardWidth -
      cardWidth / 1.5 -
      xSecondayOffsetInPiles -
      shiftDown;
    y = otherIncrement * adjustedOffset;
    rotation = 0;
  }

  let suit = card.suit;
  let rank = card.rank;

  if (down) {
    scale = 0.9 * scale;
  }
  if (card.highlight) {
    scale = 1.5 * scale;
  }

  let zIndex = card.zIndex ? card.zIndex : 0;

  let shaddowColor = "#4f5951";

  let boxShadow = `3px 2px 15px 2px ${shaddowColor}`;
  let borderRadius = cardWidth / 11.9;
  if (scale === 0.5) {
    boxShadow = `1px 1px 3px 1px ${shaddowColor}`;
    borderRadius = cardWidth / 8;
  }

  const animation = useSpring({
    x: x,
    y: y,
    config: { mass: 1.2, friction: 25, tension: 200, clamp: true },
    scale: scale,
    position: "absolute",
    transform: `rotate(${rotation}deg)`,
    zIndex: zIndex,
    opacity: 1,
    backgroundColor: `${shaddowColor}`,
    borderRadius: `${borderRadius}px`,
    boxShadow: boxShadow,
  });

  let card_img = back;
  if (card.visible === true) {
    card_img = getCard({ rank, suit });
  }

  return (
    <animated.img
      className="unselectable"
      onMouseOut={() => setDown(false)}
      onMouseDown={() => setDown(true)}
      onMouseUp={() => setDown(false)}
      style={{ ...animation }}
      src={card_img}
      onClick={() => {
        console.log(alllowPlay);
        if (card.playable === true && alllowPlay === true) {
          setAllowPlay(false);
          setCard({ ...card, inPlay: !card.inPlay });
          setNeedSync(false);
          playCard(card);
        }
      }}
      width={`${cardWidth}px`}
      height={`${1.5 * cardWidth}px`}
      key={100 * card.cardIndex}
      // draggable={false}
      onContextMenu={(e) => e.preventDefault()}
      draggable="false"
      onDragStart={(e) => e.preventDefault()}
    />
  );
}

function getCard({ rank, suit }) {
  if (rank === 2 && suit === "s") return s2;
  if (rank === 4 && suit === "s") return s4;
  if (rank === 5 && suit === "s") return s5;
  if (rank === 3 && suit === "s") return s3;
  if (rank === 6 && suit === "s") return s6;
  if (rank === 7 && suit === "s") return s7;
  if (rank === 8 && suit === "s") return s8;
  if (rank === 9 && suit === "s") return s9;
  if (rank === 10 && suit === "s") return s10;
  if (rank === 11 && suit === "s") return s11;
  if (rank === 12 && suit === "s") return s12;
  if (rank === 13 && suit === "s") return s13;
  if (rank === 14 && suit === "s") return s14;
  if (rank === 2 && suit === "h") return h2;
  if (rank === 3 && suit === "h") return h3;
  if (rank === 4 && suit === "h") return h4;
  if (rank === 5 && suit === "h") return h5;
  if (rank === 6 && suit === "h") return h6;
  if (rank === 7 && suit === "h") return h7;
  if (rank === 8 && suit === "h") return h8;
  if (rank === 9 && suit === "h") return h9;
  if (rank === 10 && suit === "h") return h10;
  if (rank === 11 && suit === "h") return h11;
  if (rank === 12 && suit === "h") return h12;
  if (rank === 13 && suit === "h") return h13;
  if (rank === 14 && suit === "h") return h14;
  if (rank === 2 && suit === "d") return d2;
  if (rank === 3 && suit === "d") return d3;
  if (rank === 4 && suit === "d") return d4;
  if (rank === 5 && suit === "d") return d5;
  if (rank === 6 && suit === "d") return d6;
  if (rank === 7 && suit === "d") return d7;
  if (rank === 8 && suit === "d") return d8;
  if (rank === 9 && suit === "d") return d9;
  if (rank === 10 && suit === "d") return d10;
  if (rank === 11 && suit === "d") return d11;
  if (rank === 12 && suit === "d") return d12;
  if (rank === 13 && suit === "d") return d13;
  if (rank === 14 && suit === "d") return d14;
  if (rank === 2 && suit === "c") return c2;
  if (rank === 3 && suit === "c") return c3;
  if (rank === 4 && suit === "c") return c4;
  if (rank === 5 && suit === "c") return c5;
  if (rank === 6 && suit === "c") return c6;
  if (rank === 7 && suit === "c") return c7;
  if (rank === 8 && suit === "c") return c8;
  if (rank === 9 && suit === "c") return c9;
  if (rank === 10 && suit === "c") return c10;
  if (rank === 11 && suit === "c") return c11;
  if (rank === 12 && suit === "c") return c12;
  if (rank === 13 && suit === "c") return c13;
  if (rank === 14 && suit === "c") return c14;
}
