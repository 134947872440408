import React from "react";
import { animated, useSpring } from "react-spring";
import { useRecoilValue } from "recoil";
import sitdownsound from "../sounds/sitdown.mp3";
import standupsound from "../sounds/standup.mp3";

import useSound from "use-sound";
import "./style.css";

export function Names({
  pageHeight,
  buffer,
  seatToNamesState,
  socket,
  playerName,
  appVolume,
}) {
  const [playSit] = useSound(sitdownsound, { volume: appVolume / 100 });
  const [playStand] = useSound(standupsound, { volume: appVolume / 100 });

  // useEffect(() => {
  //   console.log("SETTTING APP VOLUE IN NAMES");
  //   console.log(appVolume);
  //   audio.volume = appVolume;
  // }, [appVolume]);

  const seatToNames = useRecoilValue(seatToNamesState);

  let trueWidth = pageHeight;
  let width = Math.round(trueWidth);
  // let buffer = Math.round((trueWidth - width) / 2);
  let increment = Math.round(width / 16);
  let cardWidth = 2 * increment;
  let cardHeigh = Math.round((303 / 208) * cardWidth);
  let center = Math.round(width / 2);
  let scaleForOther = 0.6;
  let otherCardHeight = Math.round(cardHeigh * scaleForOther);
  let topMargin = (cardHeigh - otherCardHeight) / 2;

  let tableFull = seatToNames && seatToNames.full;
  let p0Full = seatToNames && seatToNames.p0;
  let p3Full = seatToNames && seatToNames.p3;
  let p2Full = seatToNames && seatToNames.p2;
  let p1Full = seatToNames && seatToNames.p1;

  let p0Text = p0Full ? seatToNames.p0.name : "S";
  let p3Text = p3Full ? seatToNames.p3.name : "E";
  let p2Text = p2Full ? seatToNames.p2.name : "N";
  let p1Text = p1Full ? seatToNames.p1.name : "W";

  let scaledCardWidth = 0.92 * cardWidth;

  let leftStart = trueWidth / 2 - scaledCardWidth / 2 + buffer;

  let shiftDown = cardWidth / 10;

  let prestart = {
    bottom: {
      x: leftStart,
      y: 2 * center - topMargin * 1.2 - pageHeight / 3,
    },
    right: {
      x: buffer + 2 * center - cardWidth - pageHeight / 6,
      y: pageHeight / 2 - topMargin,
    },
    top: {
      x: leftStart,
      y: pageHeight / 3,
    },
    left: {
      x: buffer + pageHeight / 6,
      y: pageHeight / 2 - topMargin,
    },
  };

  let start = {
    bottom: {
      x: leftStart,
      y: pageHeight - topMargin - shiftDown,
    },
    right: {
      x: buffer + 2 * center - cardWidth - shiftDown,
      y: shiftDown,
    },
    top: {
      x: leftStart,
      y: shiftDown,
    },
    left: {
      x: buffer + shiftDown,
      y: shiftDown,
    },
  };

  let bshaddow0 = "2px 2px 2px #9E9E9E";
  let bshaddow1 = "2px 2px 2px #9E9E9E";
  let bshaddow2 = "2px 2px 2px #9E9E9E";
  let bshaddow3 = "2px 2px 2px #9E9E9E";

  if (seatToNames && seatToNames.p0.full) {
    bshaddow0 = "0px 0px 2px 1px #9E9E9E";
  }
  if (seatToNames && seatToNames.p1.full) {
    bshaddow1 = "0px 0px 2px 1px #9E9E9E";
  }
  if (seatToNames && seatToNames.p2.full) {
    bshaddow2 = "0px 0px 2px 1px #9E9E9E";
  }
  if (seatToNames && seatToNames.p3.full) {
    bshaddow3 = "0px 0px 2px 1px #9E9E9E";
  }

  let commonStyle = {
    position: "absolute",
    height: topMargin,
    width: cardWidth,
    textAlign: "center",
    justifyContent: "center",
    fontSize: `${0.4 * topMargin}px`,
    color: "white",
    zIndex: 11,
    borderRadius: topMargin / 2,
    backgroundColor: "#352990",
    fontWeight: "bold",
  };

  if (tableFull) {
    commonStyle = {
      position: "absolute",
      height: topMargin,
      width: cardWidth,
      textAlign: "center",
      justifyContent: "center",
      fontSize: `${0.4 * topMargin}px`,
      color: "white",
      zIndex: 11,
      borderRadius: topMargin / 2,
      backgroundColor: "#352990",
      cursor: "unset",
      fontWeight: "bold",
    };
  }

  let x0 = 0;
  let y0 = 0;
  let scale0 = 0;
  let h0 = 0;
  if (seatToNames) {
    if (tableFull) {
      x0 = start[`${seatToNames.p0.relativePosition}`].x;
      y0 = start[`${seatToNames.p0.relativePosition}`].y;
      scale0 = 0.8;
      h0 = seatToNames.p0.highlight ? 1 : 0;
    } else {
      x0 = prestart[`${seatToNames.p0.relativePosition}`].x;
      y0 = prestart[`${seatToNames.p0.relativePosition}`].y;
      scale0 = seatToNames.p0.full ? 1.2 : 2;
    }
  }

  let x1 = 0;
  let y1 = 0;
  let scale1 = 0;
  let h1 = 0;
  if (seatToNames) {
    if (tableFull) {
      x1 = start[`${seatToNames.p1.relativePosition}`].x;
      y1 = start[`${seatToNames.p1.relativePosition}`].y;
      scale1 = 0.8;
      h1 = seatToNames.p1.highlight ? 1 : 0;
    } else {
      x1 = prestart[`${seatToNames.p1.relativePosition}`].x;
      y1 = prestart[`${seatToNames.p1.relativePosition}`].y;
      scale1 = seatToNames.p1.full ? 1.2 : 2;
    }
  }

  let x2 = 0;
  let y2 = 0;
  let scale2 = 0;
  let h2 = 0;
  if (seatToNames) {
    if (tableFull) {
      x2 = start[`${seatToNames.p2.relativePosition}`].x;
      y2 = start[`${seatToNames.p2.relativePosition}`].y;
      scale2 = 0.8;
      h2 = seatToNames.p2.highlight ? 1 : 0;
    } else {
      x2 = prestart[`${seatToNames.p2.relativePosition}`].x;
      y2 = prestart[`${seatToNames.p2.relativePosition}`].y;
      scale2 = seatToNames.p2.full ? 1.2 : 2;
    }
  }

  let x3 = 0;
  let y3 = 0;
  let scale3 = 0;
  let h3 = 0;
  if (seatToNames) {
    if (tableFull) {
      x3 = start[`${seatToNames.p3.relativePosition}`].x;
      y3 = start[`${seatToNames.p3.relativePosition}`].y;
      scale3 = 0.8;
      h3 = seatToNames.p3.highlight ? 1 : 0;
    } else {
      x3 = prestart[`${seatToNames.p3.relativePosition}`].x;
      y3 = prestart[`${seatToNames.p3.relativePosition}`].y;
      scale3 = seatToNames.p3.full ? 1.2 : 2;
    }
  }

  const animatio0 = useSpring({
    x: x0,
    y: y0,
    scale: scale0,
    config: { mass: 1.2, friction: 25, tension: 200 },
    boxShadow: bshaddow0,
  });

  const animatio0h = useSpring({
    x: x0,
    y: y0,
    scale: h0,
    config: { mass: 1.2, friction: 25, tension: 200 },
    transform: "scaleX(.92) scaleY(1.1)",
    boxShadow: "0px 0px 2px 1px yellow",
  });

  const animatio1 = useSpring({
    x: x1,
    y: y1,
    scale: scale1,
    config: { mass: 1.2, friction: 25, tension: 200 },
    boxShadow: bshaddow1,
  });

  const animatio1h = useSpring({
    x: x1,
    y: y1,
    scale: h1,
    config: { mass: 1.2, friction: 25, tension: 200 },
    transform: "scaleX(.92) scaleY(1.1)",
    boxShadow: "0px 0px 2px 1px yellow",
  });

  const animatio2 = useSpring({
    x: x2,
    y: y2,
    scale: scale2,
    config: { mass: 1.2, friction: 25, tension: 200 },
    boxShadow: bshaddow2,
  });

  const animatio2h = useSpring({
    x: x2,
    y: y2,
    scale: h2,
    config: { mass: 1.2, friction: 25, tension: 200 },
    transform: "scaleX(.92) scaleY(1.1)",
    boxShadow: "0px 0px 2px 1px yellow",
  });

  const animatio3 = useSpring({
    x: x3,
    y: y3,
    scale: scale3,
    config: { mass: 1.2, friction: 25, tension: 200 },
    boxShadow: bshaddow3,
  });

  const animatio3h = useSpring({
    x: x3,
    y: y3,
    scale: h3,
    config: { mass: 1.2, friction: 25, tension: 200 },
    transform: "scaleX(.92) scaleY(1.1)",
    boxShadow: "0px 0px 2px 1px yellow",
  });

  let style0 = {
    ...commonStyle,
    ...animatio0,
  };

  let style0h = {
    ...commonStyle,
    ...animatio0h,
  };

  let style1 = {
    ...commonStyle,
    ...animatio1,
  };

  let style1h = {
    ...commonStyle,
    ...animatio1h,
  };

  let style2 = {
    ...commonStyle,
    ...animatio2,
  };

  let style2h = {
    ...commonStyle,
    ...animatio2h,
  };

  let style3 = {
    ...commonStyle,
    ...animatio3,
  };

  let style3h = {
    ...commonStyle,
    ...animatio3h,
  };

  let name1 = (
    <animated.button
      className="btn-hover color-1 unselectable"
      key="name1button"
      style={style1}
      onContextMenu={(e) => {
        e.preventDefault();
        if (!tableFull) {
          if (p1Full) {
            playStand();
          } else {
            playSit();
          }
          socket.emit("computerSit", { name: playerName, seat: "p1" });
        }
      }}
      onClick={() => {
        if (!tableFull) {
          if (p1Full) {
            playStand();
          } else {
            playSit();
          }
          socket.emit("sit", { name: playerName, seat: "p1" });
        }
      }}
    >
      <div key="player1text">{p1Text}</div>
    </animated.button>
  );

  let name2 = (
    <animated.button
      className="btn-hover color-2 unselectable"
      key="name2button"
      style={style2}
      onContextMenu={(e) => {
        e.preventDefault();
        if (!tableFull) {
          if (p2Full) {
            playStand();
          } else {
            playSit();
          }
          socket.emit("computerSit", { name: playerName, seat: "p2" });
        }
      }}
      onClick={() => {
        if (!tableFull) {
          if (p2Full) {
            playStand();
          } else {
            playSit();
          }
          socket.emit("sit", { name: playerName, seat: "p2" });
        }
      }}
    >
      <div key="player2text">{p2Text} </div>
    </animated.button>
  );

  let name3 = (
    <animated.button
      className="btn-hover color-3 unselectable"
      key="name3button"
      style={style3}
      onClick={() => {
        if (!tableFull) {
          if (p3Full) {
            playStand();
          } else {
            playSit();
          }
          socket.emit("sit", { name: playerName, seat: "p3" });
        }
      }}
      onContextMenu={(e) => {
        e.preventDefault();
        if (!tableFull) {
          if (p3Full) {
            playStand();
          } else {
            playSit();
          }
          socket.emit("computerSit", { name: playerName, seat: "p3" });
        }
      }}
    >
      <div key="player3text">{p3Text}</div>
    </animated.button>
  );

  let name0 = (
    <animated.button
      className="btn-hover color-4 unselectable"
      key="name4button"
      style={style0}
      onClick={() => {
        if (!tableFull) {
          if (p0Full) {
            playStand();
          } else {
            playSit();
          }
          socket.emit("sit", { name: playerName, seat: "p0" });
        }
      }}
      onContextMenu={(e) => {
        e.preventDefault();
        if (!tableFull) {
          if (p0Full) {
            playStand();
          } else {
            playSit();
          }
          socket.emit("computerSit", { name: playerName, seat: "p0" });
        }
      }}
    >
      <div key="player0text">{p0Text}</div>
    </animated.button>
  );

  let name0h = (
    <animated.button
      className="btn-hover unselectable"
      key="name0buttonh"
      style={{
        ...style0h,
        zIndex: 0,
        backgroundColor: "yellow",
      }}
      onContextMenu={(e) => {
        e.preventDefault();
      }}
    ></animated.button>
  );
  let name1h = (
    <animated.button
      className="btn-hover unselectable"
      key="name1buttonh"
      style={{
        ...style1h,
        zIndex: 0,
        backgroundColor: "yellow",
      }}
      onContextMenu={(e) => {
        e.preventDefault();
      }}
    ></animated.button>
  );
  let name2h = (
    <animated.button
      className="btn-hover unselectable"
      key="name2buttonh"
      style={{
        ...style2h,
        zIndex: 0,
        backgroundColor: "yellow",
      }}
      onContextMenu={(e) => {
        e.preventDefault();
      }}
    ></animated.button>
  );

  let name3h = (
    <animated.button
      className="btn-hover unselectable"
      key="name3buttonh"
      style={{
        ...style3h,
        zIndex: 0,
        backgroundColor: "yellow",
      }}
      onContextMenu={(e) => {
        e.preventDefault();
      }}
    ></animated.button>
  );

  if (seatToNames) {
    return [name0, name3, name2, name1, name0h, name1h, name2h, name3h];
  } else {
    return <div></div>;
  }
}
