import ReactTooltip from "react-tooltip";
import { useRecoilValue } from "recoil";

export function ProgressBar({
  buffer,
  allocatedHeight,
  startingHeight,
  dotsState,
}) {
  let padding = buffer * 0.05;
  let barHeight = allocatedHeight / 4;
  let fontSize = `${0.15 * allocatedHeight}px`;

  let barWidth = buffer - barHeight - 2 * padding;

  const dots = useRecoilValue(dotsState);

  let boxShadow = "1px 1px 15px 1px #4f5951";

  if (!(dots && dots.firstProgress)) {
    return (
      <div
        key="defaultProgressbar"
        style={{
          position: "absolute",
          top: startingHeight,
          height: allocatedHeight,
          width: buffer,
          backgroundColor: "darkgreen",
          padding: padding,
        }}
      ></div>
    );
  }

  return (
    <div
      id="progressbar"
      key="progressbar"
      style={{
        position: "absolute",
        top: startingHeight,
        height: allocatedHeight,
        width: buffer,
        padding: padding,
        backgroundColor: "darkgreen",
        zIndex: 1000,
      }}
    >
      {dots.firstProgress.amountFilled > 0 && (
        <div
          id="firstscoreamountfill"
          key="firstscoreamountfill"
          style={{
            position: "absolute",
            top: allocatedHeight / 6,
            backgroundColor: dots.firstProgress.fillColor,
            height: barHeight,
            width:
              (barWidth * (dots.firstProgress.amountFilled * 13 + 0.3)) / 13.3,
            borderRadius: allocatedHeight / 2,
            border: `2px solid green`,
            margin: 0,
            padding: 0,
            zIndex: 501,
            transition: "width 1s ease-in",
          }}
        />
      )}

      <div
        style={{
          top: allocatedHeight / 6,
          position: "absolute",
          height: barHeight,
          width: barWidth * ((dots.firstProgress.bid + 0.3) / 13.3),
          borderRadius: allocatedHeight / 2,
          border: `2px solid ${dots.firstProgress.borderColor}`,
          backgroundColor: "green",
          margin: 0,
          padding: 0,
          zIndex: 500,
          boxShadow: boxShadow,
        }}
      />

      {[...Array(dots.firstProgress.bid)].map((e, i) => {
        let b = `2px solid ${dots.firstProgress.borderColor}`;
        return (
          <div
            id={`sdsdfsdfsd${i}`}
            key={`sdsdfsdfsd${i}`}
            style={{
              top: allocatedHeight / 6,
              position: "absolute",
              height: barHeight,
              width: barWidth * ((i + 1.3) / 13.3),
              borderRadius: allocatedHeight / 2,
              border: b,
              borderRight: `2px solid ${dots.firstProgress.borderColor}`,
              // backgroundColor: "green",
              margin: 0,
              padding: 0,
              zIndex: 900 - i,
              backgroundColor: "#00000000",
            }}
          />
        );
      })}

      <div
        id="pppfirstScoresdfsd"
        data-tip
        data-for="pppfirstScore"
        style={{
          top: allocatedHeight / 6,
          position: "absolute",
          height: barHeight,
          width: barWidth,
          borderRadius: allocatedHeight / 2,
          // border: `2px solid ${dots.secondProgress.borderColor}`,
          backgroundColor: "orange",
          margin: 0,
          padding: 0,
          zIndex: 1000,
          opacity: 0,
        }}
      />

      {dots.firstProgress.books > 0 && (
        <div
          id="pppfirstBookssdfsdfsd"
          key="pppfirstBookssdfsdfsd"
          data-tip
          data-for="pppfirstBooks"
          style={{
            position: "absolute",
            top: allocatedHeight / 6,
            left: barWidth + 1.2 * padding,
            height: barHeight,
            width: barHeight,
            borderRadius: allocatedHeight / 2,
            border: `2px solid ${dots.firstProgress.borderColor}`,
            backgroundColor: "green",
            margin: 0,
            padding: 0,
            zIndex: 100,
            textAlign: "center",
            color: "white",
            fontSize: fontSize,
            cursor: "default",
            userSelect: "none",
          }}
        >
          {dots.firstProgress.books}
        </div>
      )}

      <div style={{ zIndex: 990000999 }}>
        <ReactTooltip id="pppfirstScore" style={{ zIndex: 99999 }}>
          <span style={{ zIndex: 99999 }}>{dots.firstProgress.text}</span>
        </ReactTooltip>
      </div>

      <div style={{ zIndex: 990000999 }}>
        <ReactTooltip id="pppfirstBooks">
          <span style={{ zIndex: 100000 }}>{dots.firstProgress.bookText}</span>
        </ReactTooltip>
      </div>

      {dots.secondProgress.amountFilled > 0 && (
        <div
          id="pppsecondScorefsdfsdf"
          key="pppsecondScorefsdfsdf"
          style={{
            position: "absolute",
            top: allocatedHeight / 3 + barHeight,
            backgroundColor: dots.secondProgress.fillColor,
            height: barHeight,
            width:
              (barWidth * (dots.secondProgress.amountFilled * 13 + 0.3)) / 13.3,
            borderRadius: allocatedHeight / 2,
            border: `2px solid green`,
            margin: 0,
            padding: 0,
            zIndex: 501,
            transition: "width 1s ease-in",
          }}
        />
      )}
      <div
        style={{
          // Adding background color
          top: allocatedHeight / 3 + barHeight,
          position: "absolute",
          height: barHeight,
          width: barWidth * ((dots.secondProgress.bid + 0.3) / 13.3),
          borderRadius: allocatedHeight / 2,
          border: `2px solid ${dots.secondProgress.borderColor}`,
          backgroundColor: "green",
          margin: 0,
          padding: 0,
          zIndex: 500,
          boxShadow: boxShadow,
        }}
      />

      {[...Array(dots.secondProgress.bid)].map((e, i) => {
        let b = `2px solid ${dots.secondProgress.borderColor}`;
        return (
          <div
            id={`sdfsd${i}`}
            key={`sdfsd${i}`}
            style={{
              top: allocatedHeight / 3 + barHeight,
              position: "absolute",
              height: barHeight,
              width: barWidth * ((i + 1.3) / 13.3),
              borderRadius: allocatedHeight / 2,
              // border: `2px solid ${dots.secondProgress.borderColor}`,
              border: b,
              borderRight: `2px solid ${dots.secondProgress.borderColor}`,
              // backgroundColor: "green",
              margin: 0,
              padding: 0,
              zIndex: 900 - i,
              backgroundColor: "#00000000",
            }}
          />
        );
      })}

      <div
        id="pppsecondScoreBsfdsfsdfsds"
        data-tip
        data-for="pppsecondScoreBaaa"
        style={{
          top: allocatedHeight / 3 + barHeight,
          position: "absolute",
          height: barHeight,
          width: barWidth,
          borderRadius: allocatedHeight / 2,
          // border: `2px solid ${dots.secondProgress.borderColor}`,
          backgroundColor: "orange",
          margin: 0,
          padding: 0,
          zIndex: 1000,
          opacity: 0,
        }}
      />

      {dots.secondProgress.books > 0 && (
        <div
          id="sfsdmcsceifnisni"
          key="sfsdmcsceifnisni"
          data-tip
          data-for="pppsecondBooks"
          style={{
            position: "absolute",
            top: allocatedHeight / 3 + barHeight,
            left: barWidth + 1.2 * padding,
            height: barHeight,
            width: barHeight,
            borderRadius: allocatedHeight / 2,
            // border: `2px solid red`,

            border: `2px solid ${dots.secondProgress.borderColor}`,

            backgroundColor: "green",
            margin: 0,
            padding: 0,
            zIndex: 100,
            textAlign: "center",
            color: "white",
            fontSize: fontSize,
            cursor: "default",
            userSelect: "none",
          }}
        >
          {dots.secondProgress.books}
        </div>
      )}

      <div style={{ zIndex: 990000999 }}>
        <ReactTooltip id="pppsecondScoreBaaa" style={{ zIndex: 99999 }}>
          <span style={{ zIndex: 99999 }}>{dots.secondProgress.text}</span>
        </ReactTooltip>
      </div>
      <div style={{ zIndex: 990000999 }}>
        <ReactTooltip id="pppsecondBooks">
          <span style={{ zIndex: 100000 }}>{dots.secondProgress.bookText}</span>
        </ReactTooltip>
      </div>
    </div>
  );
}
