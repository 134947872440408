import Particles from "react-tsparticles";
// import { animated, useSpring } from "react-spring";
// import { useState } from "react";

// import "./style.css";

export function MyBackground({ pageHeight, buffer, show, color }) {
  let colors = [
    // "#400080",
    // "#6600cc",
    // "#6600cc",
    // "#0897f7",
    // "#0897f7",
    // "#0897f7",
    "#6600cc", // purple
    "#6600cc", // purple
    "#0897f7", // blue

    // "#008000",
  ];

  if (color === "red") {
    colors = [
      // "#400080",
      // "#6600cc",
      // "#6600cc",
      "#da6d78",
      "#bc88a4",
      "#008000", //green

      // "#008000",
    ];
  }

  // let bshaddow1 = "2px 2px 2px #9E9E9E";
  // let topMargin = pageHeight / 10;
  // let commonStyle = {
  //   position: "absolute",
  //   height: pageHeight / 10,
  //   width: pageHeight / 3,
  //   textAlign: "center",
  //   justifyContent: "center",
  //   fontSize: `${0.4 * topMargin}px`,
  //   color: "white",
  //   zIndex: 1000,
  //   borderRadius: topMargin / 2,
  //   backgroundColor: "#352990",
  // };

  // const animatio1 = useSpring({
  //   x: buffer + pageHeight / 2 - pageHeight / 6,
  //   y: (pageHeight * 2) / 3,
  //   scale: 1,
  //   config: { mass: 1.2, friction: 25, tension: 200 },
  //   boxShadow: bshaddow1,
  // });

  // let style1 = {
  //   ...commonStyle,
  //   ...animatio1,
  // };

  // const [buttonHit, setButtonHIt] = useState(""); // '' is the initial state value

  // if (buttonHit === true) {
  //   return (
  //     <animated.button
  //       style={{
  //         opacity: 0,
  //         transition: "width 4s ease-in",
  //       }}
  //       className="btn-hover color-1 unselectable"
  //       key="name1butsfefsefston"
  //       style={style1}
  //       onContextMenu={(e) => {
  //         setButtonHIt(false);

  //         // e.preventDefault();
  //         // if (!tableFull) {
  //         //   if (p1Full) {
  //         //     playStand();
  //         //   } else {
  //         //     playSit();
  //         //   }
  //         //   socket.emit("computerSit", { name: playerName, seat: "p1" });
  //         // }
  //       }}
  //       onClick={() => {
  //         setButtonHIt(true);
  //         // if (!tableFull) {
  //         //   if (p1Full) {
  //         //     playStand();
  //         //   } else {
  //         //     playSit();
  //         //   }
  //         //   socket.emit("sit", { name: playerName, seat: "p1" });
  //         // }
  //       }}
  //     >
  //       <div key="vicotrybutoon">Play again</div>
  //     </animated.button>
  //   );
  // }

  if (!show) {
    return <div></div>;
  }

  return (
    <div
      style={{
        transition: "width 4s ease-in",
      }}
    >
      <div>
        {/* <animated.button
          style={{
            opacity: 0,
            transition: "opacity 4s ease-in",
          }}
          className="btn-hover color-1 unselectable"
          key="asdfasfswefsaf"
          style={style1}
          onContextMenu={(e) => {
            setButtonHIt(false);

            // e.preventDefault();
            // if (!tableFull) {
            //   if (p1Full) {
            //     playStand();
            //   } else {
            //     playSit();
            //   }
            //   socket.emit("computerSit", { name: playerName, seat: "p1" });
            // }
          }}
          onClick={() => {
            setButtonHIt(true);
            // if (!tableFull) {
            //   if (p1Full) {
            //     playStand();
            //   } else {
            //     playSit();
            //   }
            //   socket.emit("sit", { name: playerName, seat: "p1" });
            // }
          }}
        >
          <div key="vicotrybutoon">Play again</div>
        </animated.button> */}
        <div
          style={{
            transition: "opacity 4s ease-in",

            // height: `${pageHeight}px`,
            zIndex: -1,
            // backgroundColor: "pink",
            position: "absolute",
            // overflow: "hidden",
            // width: pageWidth,
            // height: pageHeight,
            // left: pageWidth / 4,
            // top: pageHeight / 4,

            height: `${pageHeight / 2}px`,
            width: `${pageHeight / 2}px`,
            left: `${buffer + pageHeight / 4}px`,
            top: pageHeight / 4,
          }}
        >
          <Particles
            // style={{
            //   transition: "opacity 4s ease-in",
            // }}
            id="tsparticles"
            // init={this.particlesInit}
            // loaded={this.particlesLoaded}
            options={{
              fullScreen: {
                enable: true,
              },
              detectRetina: true,
              background: {
                color: "#000",
              },
              fpsLimit: 60,
              emitters: {
                direction: "top",
                life: {
                  count: 0,
                  duration: 0.1,
                  delay: 0.1,
                },
                rate: {
                  delay: 0.25,
                  quantity: 1,
                },
                size: {
                  width: 30,
                  height: 5,
                },
                position: {
                  y: 70,
                  x: 50,
                },
              },
              particles: {
                number: {
                  value: 0,
                },
                destroy: {
                  mode: "split",
                  split: {
                    count: 1,
                    factor: { value: 1 / 3 },
                    rate: {
                      value: {
                        min: 50,
                        max: 100,
                      },
                    },
                    particles: {
                      stroke: {
                        color: {
                          value: colors,
                        },
                        width: 1,
                      },
                      number: {
                        value: 0,
                      },
                      collisions: {
                        enable: false,
                      },
                      opacity: {
                        value: 1,
                        animation: {
                          enable: true,
                          speed: 0.7,
                          minimumValue: 0.1,
                          sync: false,
                          startValue: "max",
                          destroy: "min",
                        },
                      },
                      shape: {
                        type: "circle",
                      },
                      size: {
                        value: 1,
                        animation: {
                          enable: false,
                        },
                      },
                      life: {
                        count: 1,
                        duration: {
                          value: {
                            min: 2,
                            max: 2.5,
                          },
                        },
                      },
                      move: {
                        enable: true,
                        gravity: {
                          enable: false,
                        },
                        speed: 1.5,
                        direction: "none",
                        random: true,
                        straight: false,
                        outMode: "destroy",
                      },
                    },
                  },
                },
                life: {
                  count: 1,
                },
                shape: {
                  type: "line",
                },
                size: {
                  value: 50,
                  animation: {
                    enable: true,
                    sync: true,
                    speed: 150,
                    startValue: "max",
                    destroy: "min",
                  },
                },
                stroke: {
                  color: {
                    value: "#ffffff",
                  },
                  width: 1,
                },
                rotate: {
                  path: true,
                },
                move: {
                  enable: true,
                  gravity: {
                    acceleration: 15,
                    enable: true,
                    inverse: true,
                    maxSpeed: 100,
                  },
                  speed: { min: 10, max: 20 },
                  outModes: {
                    default: "destroy",
                    top: "none",
                  },
                  trail: {
                    fillColor: "#000",
                    enable: true,
                    length: 10,
                  },
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
}
