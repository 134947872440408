import React from "react";

import { useRecoilValue } from "recoil";

export function PathnameAndPlayers({
  effectiveHeight,
  buffer,
  seatToNamesState,
  dotsState,
}) {
  const dots = useRecoilValue(dotsState);
  const seatToNames = useRecoilValue(seatToNamesState);

  let boxShadow = "1px 1px 15px 1px #4f5951";

  if (dots && dots.firstScore) {
    return <div></div>;
  }

  let text = "";
  if (
    seatToNames &&
    seatToNames.playersInRoom &&
    seatToNames.playersInRoom.length > 0
  ) {
    if (seatToNames.playersInRoom.length === 1) {
      text = seatToNames.playersInRoom[0].replace(/ /g, "\u00a0");
    }
    if (seatToNames.playersInRoom.length === 2) {
      text =
        seatToNames.playersInRoom[0].replace(/ /g, "\u00a0") +
        " and " +
        seatToNames.playersInRoom[1].replace(/ /g, "\u00a0");
    }
    if (seatToNames.playersInRoom.length === 3) {
      text =
        seatToNames.playersInRoom[0].replace(/ /g, "\u00a0") +
        ", " +
        seatToNames.playersInRoom[1].replace(/ /g, "\u00a0") +
        ", and " +
        seatToNames.playersInRoom[2].replace(/ /g, "\u00a0");
    }
    if (seatToNames.playersInRoom.length === 4) {
      text =
        seatToNames.playersInRoom[0].replace(/ /g, "\u00a0") +
        ", " +
        seatToNames.playersInRoom[1].replace(/ /g, "\u00a0") +
        ", " +
        seatToNames.playersInRoom[2].replace(/ /g, "\u00a0") +
        ", and\u00a0" +
        seatToNames.playersInRoom[3].replace(/ /g, "\u00a0");
    }
  }

  let elementSize = Math.floor(effectiveHeight / 6);

  return (
    <div>
      <div
        style={{
          position: "absolute",
          top: `${elementSize * 0.5}px`,
          left: buffer,
          zIndex: 999,
          fontSize: `${effectiveHeight / 50}px`,
          color: "#e6e6dd",
          height: `${elementSize}px`,
          width: `${effectiveHeight}px`,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // backgroundColor: "red",
        }}
      >
        <div
          style={{
            maxWidth: effectiveHeight * 0.7,
            border: `solid #352990 2px`,
            padding: `${effectiveHeight / 100}px`,
            borderRadius: `${effectiveHeight / 40}px`,
            boxShadow: boxShadow,
          }}
        >
          Choose your seat by clicking on one of the buttons below. Have friends
          join the room (spadesforhumanity.com/
          {seatToNames && seatToNames.roomName}) and click on a seat, or right
          click on a seat (click and hold on mobile) to add a computer player.
          The game will start when all 4 seats have been filled.
        </div>
      </div>

      {/* <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 9999,
          fontSize: `${effectiveHeight / 20}px`,
          color: "#e6e6dd",
          height: `${effectiveHeight / 6}px`,
          width: `${buffer}px`,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            border: `solid #352990 2px`,
            padding: `${effectiveHeight / 70}px`,
            borderRadius: `${effectiveHeight / 30}px`,

            boxShadow: boxShadow,
          }}
        >
          {seatToNames && seatToNames.roomName}
        </div>
      </div> */}

      <div
        style={{
          position: "absolute",
          top: `${effectiveHeight - elementSize}px`,
          left: 0,
          zIndex: 999,
          fontSize: `${effectiveHeight / 50}px`,
          color: "#e6e6dd",
          height: `${elementSize}px`,
          width: `${buffer}px`,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            maxWidth: buffer * 0.7,
            border: `solid #352990 2px`,
            padding: `${effectiveHeight / 100}px`,
            borderRadius: `${effectiveHeight / 40}px`,
            boxShadow: boxShadow,
          }}
        >
          Players in room: {text}
        </div>
      </div>
    </div>
  );
}
