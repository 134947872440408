import React, { useState, useCallback } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useRecoilValue } from "recoil";
import { isMobile } from "react-device-detect";
import Linkify from "react-linkify";

export function Chat({
  pageHeight,
  buffer,
  messageState: messagesState,
  sendMessage,
  playerName,
}) {
  const [text, setText] = useState("");
  const [focused, setFocused] = useState(false);

  const messages = useRecoilValue(messagesState);

  const setRef = useCallback((node) => {
    if (node) {
      node.scrollIntoView({ smooth: true });
      setTimeout(window.scrollTo(0, 0), 100);

      // window.scrollTo({
      //   top: 0,
      //   behavior: "smooth",
      // });
    }
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    sendMessage(text);
    setText("");
    if (isMobile) {
      e.target.blur();
    }
  }

  let statusHeight = pageHeight;
  let fontSize = `${0.018 * statusHeight}px`;
  let largeFontSize = `${0.02 * statusHeight}`;

  // function onkeyDown(e) {
  //   if (e.keyCode === 13 && e.shiftKey === false) {
  //     sendMessage(text);
  //     setText("");
  //     // if (isMobile){

  //     // }
  //     e.target.blur();
  //   }
  // }

  // function onKeyUp(e) {
  //   if (e.keyCode === 13 && e.shiftKey === false) {
  //     setText("");
  //   }
  // }

  function onChange(e) {
    setText(e.target.value);
    if (e.target.value.includes("\n")) {
      handleSubmit(e);
      // e.target.blur();
      // scrollToTop();
    }
  }

  return (
    <div
      className="d-flex"
      style={{
        height: `${pageHeight * 0.92}px`,
        width: `${buffer}px`,
        backgroundColor: "darkgreen",
        left: `${pageHeight + buffer}px`,
        top: `${pageHeight * 0.08}px`,
        position: "absolute",
        zIndex: 100,
      }}
    >
      <div
        className="d-flex flex-column flex-grow-1"
        style={{
          // margin: statusHeight / 38,
          // borderRadius: "8vh",
          // borderTopLeftRadius: statusHeight / 10,
          // backgroundColor: "red",
          overflow: "hidden",
          // border: "solid white",
        }}
      >
        <div className="flex-grow-1 overflow-auto" style={{}}>
          <div className="d-flex flex-column align-items-start justify-content-end px-3">
            {messages.map((message, index) => {
              const lastMessage = messages.length - 1 === index;
              const fromMe = playerName === message.from;

              let doubleMessage = false;
              if (index + 1 < messages.length) {
                if (messages[index + 1].from === message.from) {
                  doubleMessage = true;
                }
              }

              return (
                // <div key={`${index}outter`}>
                <div
                  ref={lastMessage ? setRef : null}
                  key={index}
                  className={`my-1 d-flex flex-column ${
                    fromMe ? "align-self-end" : ""
                  }`}
                >
                  <div
                    style={{
                      maxWidth: `${buffer * 0.85}px`,
                      fontSize: `${largeFontSize}px`,
                      wordWrap: "break-word",
                      color: "#e6e6dd",
                      border: `solid ${message.color} 2px`,
                      // borderRadius: `10px`,
                      borderRadius: `${largeFontSize}px`,
                      padding: `${largeFontSize * 0.2}px ${
                        largeFontSize * 0.5
                      }px ${largeFontSize * 0.2}px ${largeFontSize * 0.5}px`,
                      // backgroundColor: "lightgreen",

                      textAlign: "center",
                      backgroundColor: "green",
                    }}
                    // className={`rounded px-2 py-1   ${
                    //   // fromMe ? "background-blue text-white" : "border"
                    //   fromMe ? " text-white" : "text-white"
                    // }`}
                  >
                    <Linkify
                      componentDecorator={(
                        decoratedHref,
                        decoratedText,
                        key
                      ) => (
                        <a
                          className="menuButton"
                          // style={{ color: "red" }}
                          target="blank"
                          href={decoratedHref}
                          key={key}
                        >
                          {decoratedText}
                        </a>
                      )}
                    >
                      {message.text}
                    </Linkify>
                  </div>
                  {!doubleMessage && message.from !== "server" && (
                    <div
                      className={` ${fromMe ? "text-right" : ""}`}
                      style={{
                        fontSize: fontSize,
                        paddingBottom: `${largeFontSize}px`,
                        // color: "#d86f7a",
                        color: message.color,
                      }}
                      // className={`text-muted  ${fromMe ? "text-right" : ""}`}
                    >
                      {fromMe ? "You" : message.from}

                      {/* <div>more</div> */}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>

        <div
          style={{
            // Spaces text up above the input text
            color: "red",
            height: `${0.04 * pageHeight}px`,
            resize: "none",
            // backgroundColor: "pink",
          }}
        />
        <Form onSubmit={handleSubmit}>
          <Form.Group className="m-2">
            <InputGroup>
              <Form.Control
                className="inputText my-form-control"
                as="textarea"
                // required
                value={text}
                onChange={onChange}
                // onKeyUp={(e) => onkeyDown(e)}
                // onKeyUp={onKeyUp}
                // onKeyDown={(e) => onkeyDown(e)}
                onFocus={() => {
                  setFocused(true);
                }}
                onBlur={() => {
                  setFocused(false);
                }}
                style={{
                  color: "white",
                  height: `${0.1 * pageHeight}px`,
                  resize: "none",
                  fontSize: `${largeFontSize}px`,
                  backgroundColor: "green",
                  border: `${
                    focused ? "#b3ffb3 solid 2px" : "#b3ffb3 solid 1px"
                  } `,
                  margin: `${pageHeight * 0.005}px`,
                }}
              ></Form.Control>
            </InputGroup>
          </Form.Group>
        </Form>
      </div>
    </div>
  );
}
