import React from "react";

export function TopRight({ pageHeight, buffer }) {
  return (
    <div
      className="d-flex"
      style={{
        height: `${pageHeight * 0.15}px`,
        width: `${buffer}px`,
        backgroundColor: "darkgreen",
        left: `${pageHeight + buffer}px`,
        top: `${0}px`,
        position: "absolute",
        zIndex: 100,
        // borderBottom: "5px solid green",
      }}
    />
  );
}
