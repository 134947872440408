import Home from "./Home";
import { RecoilRoot, atom } from "recoil";
import io from "socket.io-client";
import useLocalStorage from "../hooks/useLocalStorage";
import React, { useState, useEffect, Component } from "react";
import { StateManager, CardStateHelper } from "./State";
import whoosh from "../sounds/whoosh1.mp3";
import winwhoosh from "../sounds/winwhoosh.mp3";
import magicappear from "../sounds/349201__hotlavaman__magic-mallet.mp3";
import bidsound from "../sounds/241809__suntemple__magic-sfx-for-games.mp3";
import nilSound from "../sounds/572626__philracoindie__videogame-menu-selection-sound.mp3";

import "./style.css";

import { ProgressBar } from "./ProgressBar";
import { TopProgressBar } from "./TopProgressBar";
import { Email } from "./Email";
import { EmailThankYou } from "./EmailThankYou";
import { Settings } from "./Settings";
import { MyVideo } from "./Video";
import { Card } from "./Cards";
import { Chat } from "./Chat";
import { TopRight } from "./TopRight";
import { defaultCards } from "./aux/defaultCards.js";
import { Names } from "./Names.js";
import { Bids } from "./Bids.js";
import { Menu } from "./Menu.js";
import { Rules } from "./Rules.js";
import { Welcome } from "./Welcome.js";
import { useRecoilValue } from "recoil";
import { MyBackground } from "./Background";
import { useLocation, BrowserRouter, Route, Redirect } from "react-router-dom";
import { PathnameAndPlayers } from "./PathnameAndPlayers";
// import Modal from "react-bootstrap/Modal";
// import Button from "react-bootstrap/Button";

// function iOS() {
//   return (
//     [
//       "iPad Simulator",
//       "iPhone Simulator",
//       "iPod Simulator",
//       "iPad",
//       "iPhone",
//       "iPod",
//     ].includes(navigator.platform) ||
//     // iPad on iOS 13 detection
//     (navigator.userAgent.includes("Mac") && "ontouchend" in document)
//   );
// }

let endPoint = "https://server.spadesforhumanity.com";

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  endPoint = "http://localhost:3334";
}

function sendEmail(playerName, email, text) {
  // socket.emit("email", { from: playerName, text: text });

  fetch(endPoint + "/sendEmail", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      playerName: playerName,
      email: email,
      text: text,
    }),
  });
}

const audio = new Audio();

let sendAudio = localStorage.getItem("spadesforhumanity.com-sendAudio");
if (sendAudio == null) {
  sendAudio = false;
} else {
  sendAudio = JSON.parse(sendAudio);
}

let sendVideo = localStorage.getItem("spadesforhumanity.com-sendVideo");
if (sendVideo == null) {
  sendVideo = false;
} else {
  sendVideo = JSON.parse(sendVideo);
}

let showSelf = localStorage.getItem("spadesforhumanity.com-showSelf");
if (showSelf == null) {
  showSelf = false;
} else {
  showSelf = JSON.parse(showSelf);
}

let effectsVolume = localStorage.getItem("spadesforhumanity.com-effectsVolume");
if (effectsVolume == null) {
  effectsVolume = 50;
} else {
  effectsVolume = JSON.parse(effectsVolume);
}

// console.log("IIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIII");
// console.log(sendAudio);
// console.log(sendVideo);
// console.log(showSelf);
// console.log(effectsVolume);
// console.log("IIIIIIIIIIIIIIIIIIIII");

const cardState0 = atom({
  key: "card0",
  default: defaultCards[0],
});
const cardState1 = atom({
  key: "card1",
  default: defaultCards[1],
});
const cardState2 = atom({
  key: "card2",
  default: defaultCards[2],
});
const cardState3 = atom({
  key: "card3",
  default: defaultCards[3],
});
const cardState4 = atom({
  key: "card4",
  default: defaultCards[4],
});
const cardState5 = atom({
  key: "card5",
  default: defaultCards[5],
});
const cardState6 = atom({
  key: "card6",
  default: defaultCards[6],
});
const cardState7 = atom({
  key: "card7",
  default: defaultCards[7],
});
const cardState8 = atom({
  key: "card8",
  default: defaultCards[8],
});
const cardState9 = atom({
  key: "card9",
  default: defaultCards[9],
});
const cardState10 = atom({
  key: "card10",
  default: defaultCards[10],
});
const cardState11 = atom({
  key: "card11",
  default: defaultCards[11],
});
const cardState12 = atom({
  key: "card12",
  default: defaultCards[12],
});
const cardState13 = atom({
  key: "card13",
  default: defaultCards[13],
});
const cardState14 = atom({
  key: "card14",
  default: defaultCards[14],
});
const cardState15 = atom({
  key: "card15",
  default: defaultCards[15],
});
const cardState16 = atom({
  key: "card16",
  default: defaultCards[16],
});
const cardState17 = atom({
  key: "card17",
  default: defaultCards[17],
});
const cardState18 = atom({
  key: "card18",
  default: defaultCards[18],
});
const cardState19 = atom({
  key: "card19",
  default: defaultCards[19],
});
const cardState20 = atom({
  key: "card20",
  default: defaultCards[20],
});
const cardState21 = atom({
  key: "card21",
  default: defaultCards[21],
});
const cardState22 = atom({
  key: "card22",
  default: defaultCards[22],
});
const cardState23 = atom({
  key: "card23",
  default: defaultCards[23],
});
const cardState24 = atom({
  key: "card24",
  default: defaultCards[24],
});
const cardState25 = atom({
  key: "card25",
  default: defaultCards[25],
});
const cardState26 = atom({
  key: "card26",
  default: defaultCards[26],
});
const cardState27 = atom({
  key: "card27",
  default: defaultCards[27],
});
const cardState28 = atom({
  key: "card28",
  default: defaultCards[28],
});
const cardState29 = atom({
  key: "card29",
  default: defaultCards[29],
});
const cardState30 = atom({
  key: "card30",
  default: defaultCards[30],
});
const cardState31 = atom({
  key: "card31",
  default: defaultCards[31],
});
const cardState32 = atom({
  key: "card32",
  default: defaultCards[32],
});
const cardState33 = atom({
  key: "card33",
  default: defaultCards[33],
});
const cardState34 = atom({
  key: "card34",
  default: defaultCards[34],
});
const cardState35 = atom({
  key: "card35",
  default: defaultCards[35],
});
const cardState36 = atom({
  key: "card36",
  default: defaultCards[36],
});
const cardState37 = atom({
  key: "card37",
  default: defaultCards[37],
});
const cardState38 = atom({
  key: "card38",
  default: defaultCards[38],
});
const cardState39 = atom({
  key: "card39",
  default: defaultCards[39],
});
const cardState40 = atom({
  key: "card40",
  default: defaultCards[40],
});
const cardState41 = atom({
  key: "card41",
  default: defaultCards[41],
});
const cardState42 = atom({
  key: "card42",
  default: defaultCards[42],
});
const cardState43 = atom({
  key: "card43",
  default: defaultCards[43],
});
const cardState44 = atom({
  key: "card44",
  default: defaultCards[44],
});
const cardState45 = atom({
  key: "card45",
  default: defaultCards[45],
});
const cardState46 = atom({
  key: "card46",
  default: defaultCards[46],
});
const cardState47 = atom({
  key: "card47",
  default: defaultCards[47],
});
const cardState48 = atom({
  key: "card48",
  default: defaultCards[48],
});
const cardState49 = atom({
  key: "card49",
  default: defaultCards[49],
});
const cardState50 = atom({
  key: "card50",
  default: defaultCards[50],
});
const cardState51 = atom({
  key: "card51",
  default: defaultCards[51],
});

// Contains state recieved from server
// Always updaited when server sends state
const stateState = atom({
  key: "state",
  default: { messages: ["default"], names: ["d"] },
});

const allowPlayState = atom({
  key: "allowPlayState",
  default: false,
});

// If true, syncs card state with local stateState
// If false, played cards can be update card states without modifying stateState
// In this case, F data should be sent to the server and the response should
// update stateState and set needSyncState to true
const needSyncState = atom({
  key: "syncedState", // unique ID (with respect Cato other atoms/selectors)
  default: true,
});

const messageState = atom({
  key: "message", // unique ID (with respect to other atoms/selectors)
  default: [{ from: "Server", text: "Hello And Welcome" }], // default value (aka initial value)
});

const seatToNamesState = atom({
  key: "setToNamesState", // unique ID (with respect to other atoms/selectors)
});

const videoSate0 = atom({
  key: "videoSate0", // unique ID (with respect to other atoms/selectors)
});

const videoSate1 = atom({
  key: "videoSate1", // unique ID (with respect to other atoms/selectors)
});

const videoSate2 = atom({
  key: "videoSate2", // unique ID (with respect to other atoms/selectors)
});

const videoSate3 = atom({
  key: "videoSate3", // unique ID (with respect to other atoms/selectors)
});

const positionToVideoState = atom({
  key: "positionToVideoState",
  default: { p0: 0, p1: 1, p2: 2, p3: 3 },
});

const emailState = atom({
  key: "emailState",
  default: false,
});

const emailThankYouState = atom({
  key: "emailThankYouState",
  default: false,
});

const settingsState = atom({
  key: "settingsState",
  default: {
    show: false,
    showSelf: showSelf,
    sendVideo: sendVideo,
    sendAudio: sendAudio,
    effectsVolume: effectsVolume,
  },
});

const rulesState = atom({
  key: "rulesState",
  default: false,
});

const welcomeInfoState = atom({
  key: "welcomeInfoState",
  default: true,
});

const dotsState = atom({
  key: "dotsState",
  default: {
    1: {
      white: false,
      taken: 0,
      bid: 0,
      color: "white",
    },
    2: {
      white: false,
      taken: 0,
      bid: 0,
      color: "white",
    },
    3: {
      white: false,
      taken: 0,
      bid: 0,
      color: "white",
    },
    0: {
      white: false,
      taken: 0,
      bid: 0,
      color: "white",
    },
  },
});

class App extends Component {
  render() {
    return (
      <div>
        <BrowserRouter>
          <RecoilRoot>
            <Route basename="/" component={HomeAndRoom} />
          </RecoilRoot>
        </BrowserRouter>
      </div>
    );
  }
}

function HomeAndRoom() {
  const [showHome, setShowHome] = useState(false);

  const location = useLocation();

  const [playerName, setPlayerName] = useLocalStorage("playerName");

  const [targetPath, setTargetpath] = useState(location.pathname);
  const [homeCursorPosition, setHomeCursorPosition] = useState(0);
  const [playerNameTyped, setPlayerNameTyped] = useState(playerName);
  // console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx");
  // console.log(location.pathname);
  let cursorOnPlayerDefault = true;
  if (location.pathname === "/") {
    cursorOnPlayerDefault = false;
  }
  const [cursorOnPlayer, setCursorOnPlayer] = useState(cursorOnPlayerDefault);

  const [appVolume, setAppVolume] = useState(effectsVolume);

  const [pageHeight, setPageHeight] = useState(500);
  const [pageWidth, setPageWidth] = useState(500);

  const [errorOccured, setErrorOccured] = useState(false);

  let effectiveHeight = Math.min(Math.floor(pageWidth * 0.6), pageHeight);
  let buffer = Math.floor((pageWidth - effectiveHeight) / 2);

  useEffect(() => {
    function handleResize() {
      console.log("resized to: ", window.innerWidth, "x", window.innerHeight);
      if (window.innerWidth) {
        setPageHeight(window.innerHeight);
      }
      if (window.innerHeight) {
        setPageWidth(window.innerWidth);
      }
    }
    window.addEventListener("resize", handleResize);
  });

  useEffect(() => {
    if (window.innerWidth) {
      setPageHeight(window.innerHeight);
    }
    if (window.innerHeight) {
      setPageWidth(window.innerWidth);
    }
  }, [setPageHeight, setPageWidth]);

  useEffect(() => {
    console.log("APP VOLUME CHANGED");
    if (appVolume) {
      console.log("SETTTING APP VOLUE");
      console.log(appVolume);
      audio.volume = appVolume / 100;
    }
  }, [appVolume]);

  if (targetPath !== location.pathname) {
    return <Redirect to={targetPath} />;
  }

  if (playerName === "reload") {
    setPlayerName("");
    console.log("RELOADING");
    window.location.reload();
  }

  if (targetPath === "/" || !playerName || errorOccured !== false) {
    if (!showHome) {
      setShowHome(true);
    }
    // setShowHome(true);
  } else {
    if (showHome) {
      // setShowHome(false);
    }
  }

  if (showHome) {
    return (
      <div>
        <Email
          emailState={emailState}
          sendEmail={(email, text) => sendEmail("NotLoggedIN", email, text)}
          emailThankYouState={emailThankYouState}
        />
        <EmailThankYou emailThankYouState={emailThankYouState} />
        <Settings
          settingsState={settingsState}
          appVolume={appVolume}
          setAppVolume={setAppVolume}
        />
        <Rules rulesState={rulesState} />
        <Welcome welcomeInfoState={welcomeInfoState} />
        <Menu
          buffer={buffer}
          pageHeight={effectiveHeight}
          playerName={null}
          setPlayerName={setPlayerName}
          setTargetpath={setTargetpath}
          emailState={emailState}
          settingsState={settingsState}
          rulesState={rulesState}
          welcomeInfoState={welcomeInfoState}
          errorOccured={errorOccured}
          setErrorOccured={setErrorOccured}
          seatToNamesState={seatToNamesState}
        />
        <Home
          onIdSubmit={setPlayerName}
          playerName={playerName}
          setTargetpath={setTargetpath}
          targetPath={targetPath}
          homeCursorPosition={homeCursorPosition}
          setHomeCursorPosition={setHomeCursorPosition}
          errorOccured={errorOccured}
          setErrorOccured={setErrorOccured}
          setShowHome={setShowHome}
          playerNameTyped={playerNameTyped}
          setPlayerNameTyped={setPlayerNameTyped}
          cursorOnPlayer={cursorOnPlayer}
          setCursorOnPlayer={setCursorOnPlayer}
        />
      </div>
    );
  }

  return (
    <App1
      playerName={playerName}
      setPlayerName={setPlayerName}
      targetPath={targetPath}
      setTargetpath={setTargetpath}
      appVolume={appVolume}
      setAppVolume={setAppVolume}
      buffer={buffer}
      effectiveHeight={effectiveHeight}
      setErrorOccured={setErrorOccured}
    />
  );
}

function App1({
  playerName,
  setPlayerName,
  targetPath,
  setTargetpath,
  appVolume,
  setAppVolume,
  buffer,
  effectiveHeight,
  setErrorOccured,
}) {
  console.log("App1");

  const [socket, setSocket] = useState();

  function sendMessage(text) {
    socket.emit("message", { from: playerName, text: text });
  }

  function playCard(card) {
    console.log("PLAYING A CARD");
    socket.emit("play", {
      playerName: playerName,
      cardIndex: card.cardIndex,
    });
  }

  function placeBid(bid) {
    console.log("PLACED A BID");
    socket.emit("bid", {
      playerName: playerName,
      bid: bid,
    });
  }

  useEffect(() => {
    console.log("playerName has changed to:");
    console.log(playerName);
    console.log("Connecting to server");
    if (playerName && setErrorOccured) {
      let s = io.connect(`${endPoint}`, {
        query: { name: playerName, pathname: targetPath },
      });

      s.on("sound", (s) => {
        // console.log("In sound");
        // console.log(s);
        if (s.sound === "bid") {
          audio.src = bidsound;
        }
        if (s.sound === "nilSound") {
          audio.src = nilSound;
        }
        if (s.sound === "playHigh") {
          audio.src = whoosh;
        }
        if (s.sound === "playLow") {
          audio.src = winwhoosh;
        }
        if (s.sound === "playerJoin") {
          audio.src = magicappear;
        }
        let playPromise = audio.play();
        if (playPromise !== undefined) {
          playPromise.then((_) => {}).catch((error) => {});
        }
      });

      s.on("error", (s) => {
        setErrorOccured(s.message);
      });

      setSocket(s);
      s.emit("ping", "Empty");
    }
  }, [playerName, setSocket, targetPath, setErrorOccured]);

  function Cards({ show }) {
    let cards = (
      <div>
        <Card
          cardState={cardState0}
          pageHeight={effectiveHeight}
          buffer={buffer}
          needSyncState={needSyncState}
          playCard={playCard}
          allowPlayState={allowPlayState}
        />
        <Card
          cardState={cardState1}
          pageHeight={effectiveHeight}
          buffer={buffer}
          needSyncState={needSyncState}
          playCard={playCard}
          allowPlayState={allowPlayState}
        />
        <Card
          cardState={cardState2}
          pageHeight={effectiveHeight}
          buffer={buffer}
          needSyncState={needSyncState}
          playCard={playCard}
          allowPlayState={allowPlayState}
        />
        <Card
          cardState={cardState3}
          pageHeight={effectiveHeight}
          buffer={buffer}
          needSyncState={needSyncState}
          playCard={playCard}
          allowPlayState={allowPlayState}
        />
        <Card
          cardState={cardState4}
          pageHeight={effectiveHeight}
          buffer={buffer}
          needSyncState={needSyncState}
          playCard={playCard}
          allowPlayState={allowPlayState}
        />
        <Card
          cardState={cardState5}
          pageHeight={effectiveHeight}
          buffer={buffer}
          needSyncState={needSyncState}
          playCard={playCard}
          allowPlayState={allowPlayState}
        />
        <Card
          cardState={cardState6}
          pageHeight={effectiveHeight}
          buffer={buffer}
          needSyncState={needSyncState}
          playCard={playCard}
          allowPlayState={allowPlayState}
        />
        <Card
          cardState={cardState7}
          pageHeight={effectiveHeight}
          buffer={buffer}
          needSyncState={needSyncState}
          playCard={playCard}
          allowPlayState={allowPlayState}
        />
        <Card
          cardState={cardState8}
          pageHeight={effectiveHeight}
          buffer={buffer}
          needSyncState={needSyncState}
          playCard={playCard}
          allowPlayState={allowPlayState}
        />
        <Card
          cardState={cardState9}
          pageHeight={effectiveHeight}
          buffer={buffer}
          needSyncState={needSyncState}
          playCard={playCard}
          allowPlayState={allowPlayState}
        />
        <Card
          cardState={cardState10}
          pageHeight={effectiveHeight}
          buffer={buffer}
          needSyncState={needSyncState}
          playCard={playCard}
          allowPlayState={allowPlayState}
        />
        <Card
          cardState={cardState11}
          pageHeight={effectiveHeight}
          buffer={buffer}
          needSyncState={needSyncState}
          playCard={playCard}
          allowPlayState={allowPlayState}
        />
        <Card
          cardState={cardState12}
          pageHeight={effectiveHeight}
          buffer={buffer}
          needSyncState={needSyncState}
          playCard={playCard}
          allowPlayState={allowPlayState}
        />
        <Card
          cardState={cardState13}
          pageHeight={effectiveHeight}
          buffer={buffer}
          needSyncState={needSyncState}
          playCard={playCard}
          allowPlayState={allowPlayState}
        />
        <Card
          cardState={cardState14}
          pageHeight={effectiveHeight}
          buffer={buffer}
          needSyncState={needSyncState}
          playCard={playCard}
          allowPlayState={allowPlayState}
        />
        <Card
          cardState={cardState15}
          pageHeight={effectiveHeight}
          buffer={buffer}
          needSyncState={needSyncState}
          playCard={playCard}
          allowPlayState={allowPlayState}
        />
        <Card
          cardState={cardState16}
          pageHeight={effectiveHeight}
          buffer={buffer}
          needSyncState={needSyncState}
          playCard={playCard}
          allowPlayState={allowPlayState}
        />
        <Card
          cardState={cardState17}
          pageHeight={effectiveHeight}
          buffer={buffer}
          needSyncState={needSyncState}
          playCard={playCard}
          allowPlayState={allowPlayState}
        />
        <Card
          cardState={cardState18}
          pageHeight={effectiveHeight}
          buffer={buffer}
          needSyncState={needSyncState}
          playCard={playCard}
          allowPlayState={allowPlayState}
        />
        <Card
          cardState={cardState19}
          pageHeight={effectiveHeight}
          buffer={buffer}
          needSyncState={needSyncState}
          playCard={playCard}
          allowPlayState={allowPlayState}
        />
        <Card
          cardState={cardState20}
          pageHeight={effectiveHeight}
          buffer={buffer}
          needSyncState={needSyncState}
          playCard={playCard}
          allowPlayState={allowPlayState}
        />
        <Card
          cardState={cardState21}
          pageHeight={effectiveHeight}
          buffer={buffer}
          needSyncState={needSyncState}
          playCard={playCard}
          allowPlayState={allowPlayState}
        />
        <Card
          cardState={cardState22}
          pageHeight={effectiveHeight}
          buffer={buffer}
          needSyncState={needSyncState}
          playCard={playCard}
          allowPlayState={allowPlayState}
        />
        <Card
          cardState={cardState23}
          pageHeight={effectiveHeight}
          buffer={buffer}
          needSyncState={needSyncState}
          playCard={playCard}
          allowPlayState={allowPlayState}
        />
        <Card
          cardState={cardState24}
          pageHeight={effectiveHeight}
          buffer={buffer}
          needSyncState={needSyncState}
          playCard={playCard}
          allowPlayState={allowPlayState}
        />
        <Card
          cardState={cardState25}
          pageHeight={effectiveHeight}
          buffer={buffer}
          needSyncState={needSyncState}
          playCard={playCard}
          allowPlayState={allowPlayState}
        />
        <Card
          cardState={cardState26}
          pageHeight={effectiveHeight}
          buffer={buffer}
          needSyncState={needSyncState}
          playCard={playCard}
          allowPlayState={allowPlayState}
        />
        <Card
          cardState={cardState27}
          pageHeight={effectiveHeight}
          buffer={buffer}
          needSyncState={needSyncState}
          playCard={playCard}
          allowPlayState={allowPlayState}
        />
        <Card
          cardState={cardState28}
          pageHeight={effectiveHeight}
          buffer={buffer}
          needSyncState={needSyncState}
          playCard={playCard}
          allowPlayState={allowPlayState}
        />
        <Card
          cardState={cardState29}
          pageHeight={effectiveHeight}
          buffer={buffer}
          needSyncState={needSyncState}
          playCard={playCard}
          allowPlayState={allowPlayState}
        />
        <Card
          cardState={cardState30}
          pageHeight={effectiveHeight}
          buffer={buffer}
          needSyncState={needSyncState}
          playCard={playCard}
          allowPlayState={allowPlayState}
        />
        <Card
          cardState={cardState31}
          pageHeight={effectiveHeight}
          buffer={buffer}
          needSyncState={needSyncState}
          playCard={playCard}
          allowPlayState={allowPlayState}
        />
        <Card
          cardState={cardState32}
          pageHeight={effectiveHeight}
          buffer={buffer}
          needSyncState={needSyncState}
          playCard={playCard}
          allowPlayState={allowPlayState}
        />
        <Card
          cardState={cardState33}
          pageHeight={effectiveHeight}
          buffer={buffer}
          needSyncState={needSyncState}
          playCard={playCard}
          allowPlayState={allowPlayState}
        />
        <Card
          cardState={cardState34}
          pageHeight={effectiveHeight}
          buffer={buffer}
          needSyncState={needSyncState}
          playCard={playCard}
          allowPlayState={allowPlayState}
        />
        <Card
          cardState={cardState35}
          pageHeight={effectiveHeight}
          buffer={buffer}
          needSyncState={needSyncState}
          playCard={playCard}
          allowPlayState={allowPlayState}
        />
        <Card
          cardState={cardState36}
          pageHeight={effectiveHeight}
          buffer={buffer}
          needSyncState={needSyncState}
          playCard={playCard}
          allowPlayState={allowPlayState}
        />
        <Card
          cardState={cardState37}
          pageHeight={effectiveHeight}
          buffer={buffer}
          needSyncState={needSyncState}
          playCard={playCard}
          allowPlayState={allowPlayState}
        />
        <Card
          cardState={cardState38}
          pageHeight={effectiveHeight}
          buffer={buffer}
          needSyncState={needSyncState}
          playCard={playCard}
          allowPlayState={allowPlayState}
        />
        <Card
          cardState={cardState39}
          pageHeight={effectiveHeight}
          buffer={buffer}
          needSyncState={needSyncState}
          playCard={playCard}
          allowPlayState={allowPlayState}
        />
        <Card
          cardState={cardState40}
          pageHeight={effectiveHeight}
          buffer={buffer}
          needSyncState={needSyncState}
          playCard={playCard}
          allowPlayState={allowPlayState}
        />
        <Card
          cardState={cardState41}
          pageHeight={effectiveHeight}
          buffer={buffer}
          needSyncState={needSyncState}
          playCard={playCard}
          allowPlayState={allowPlayState}
        />
        <Card
          cardState={cardState42}
          pageHeight={effectiveHeight}
          buffer={buffer}
          needSyncState={needSyncState}
          playCard={playCard}
          allowPlayState={allowPlayState}
        />
        <Card
          cardState={cardState43}
          pageHeight={effectiveHeight}
          buffer={buffer}
          needSyncState={needSyncState}
          playCard={playCard}
          allowPlayState={allowPlayState}
        />
        <Card
          cardState={cardState44}
          pageHeight={effectiveHeight}
          buffer={buffer}
          needSyncState={needSyncState}
          playCard={playCard}
          allowPlayState={allowPlayState}
        />
        <Card
          cardState={cardState45}
          pageHeight={effectiveHeight}
          buffer={buffer}
          needSyncState={needSyncState}
          playCard={playCard}
          allowPlayState={allowPlayState}
        />
        <Card
          cardState={cardState46}
          pageHeight={effectiveHeight}
          buffer={buffer}
          needSyncState={needSyncState}
          playCard={playCard}
          allowPlayState={allowPlayState}
        />
        <Card
          cardState={cardState47}
          pageHeight={effectiveHeight}
          buffer={buffer}
          needSyncState={needSyncState}
          playCard={playCard}
          allowPlayState={allowPlayState}
        />
        <Card
          cardState={cardState48}
          pageHeight={effectiveHeight}
          buffer={buffer}
          needSyncState={needSyncState}
          playCard={playCard}
          allowPlayState={allowPlayState}
        />
        <Card
          cardState={cardState49}
          pageHeight={effectiveHeight}
          buffer={buffer}
          needSyncState={needSyncState}
          playCard={playCard}
          allowPlayState={allowPlayState}
        />
        <Card
          cardState={cardState50}
          pageHeight={effectiveHeight}
          buffer={buffer}
          needSyncState={needSyncState}
          playCard={playCard}
          allowPlayState={allowPlayState}
        />
        <Card
          cardState={cardState51}
          pageHeight={effectiveHeight}
          buffer={buffer}
          needSyncState={needSyncState}
          playCard={playCard}
          allowPlayState={allowPlayState}
        />
      </div>
    );

    if (show) {
      return cards;
    } else {
      return <div></div>;
    }
  }

  function AllButNames() {
    const seatToNames = useRecoilValue(seatToNamesState);

    let r = (
      <div
        key="sdfjk39jsf"
        // style={{
        //   backgroundColor: "red",
        //   width: "160vh",
        //   height: "100vh",
        //   zIndex: -1000,
        // }}
      >
        <Cards key="cardssjfsf" show={seatToNames && seatToNames.full} />

        <MyBackground
          pageHeight={effectiveHeight}
          buffer={buffer}
          show={
            seatToNames && !seatToNames.full && seatToNames.previousWinningColor
          }
          color={seatToNames && seatToNames.previousWinningColor}
        />

        {/* <div
          style={{
            // backgroundColor: "green",
            width: "100vh",
            height: "100vh",
            zIndex: -1000,
            position: "absolute",
            top: 0,
          }}
        ></div> */}
      </div>
    );

    if (seatToNames) {
      return r;
    } else {
      return <div>Connecting to server</div>;
    }
  }

  let progressHeight = Math.floor(effectiveHeight * 0.15);
  let contVideoAllowance = effectiveHeight - 2 * progressHeight;

  const dashboard = (
    <div>
      <Email
        emailState={emailState}
        sendEmail={(email, text) => sendEmail(playerName, email, text)}
        emailThankYouState={emailThankYouState}
      />
      <EmailThankYou emailThankYouState={emailThankYouState} />
      <Settings
        settingsState={settingsState}
        appVolume={appVolume}
        setAppVolume={setAppVolume}
      />
      <Rules rulesState={rulesState} />
      <Welcome welcomeInfoState={welcomeInfoState} />
      <Menu
        buffer={buffer}
        pageHeight={effectiveHeight}
        playerName={playerName}
        setPlayerName={setPlayerName}
        setTargetpath={setTargetpath}
        emailState={emailState}
        settingsState={settingsState}
        rulesState={rulesState}
        welcomeInfoState={welcomeInfoState}
        seatToNamesState={seatToNamesState}
      />
      <PathnameAndPlayers
        effectiveHeight={effectiveHeight}
        buffer={buffer}
        seatToNamesState={seatToNamesState}
        dotsState={dotsState}
      />
      <ProgressBar
        allocatedHeight={progressHeight}
        startingHeight={contVideoAllowance + progressHeight}
        buffer={buffer}
        dotsState={dotsState}
      />
      <TopProgressBar
        allocatedHeight={progressHeight}
        startingHeight={contVideoAllowance}
        buffer={buffer}
        dotsState={dotsState}
      />
      <MyVideo
        videoSate0={videoSate0}
        videoSate1={videoSate1}
        videoSate2={videoSate2}
        videoSate3={videoSate3}
        socket={socket}
        pageHeight={contVideoAllowance}
        buffer={buffer}
        dotsState={dotsState}
        positionToVideoState={positionToVideoState}
        progressHeight={progressHeight}
      />
      <Names
        pageHeight={effectiveHeight}
        buffer={buffer}
        seatToNamesState={seatToNamesState}
        playerName={playerName}
        socket={socket}
        appVolume={appVolume}
      />
      <Bids
        pageHeight={effectiveHeight}
        buffer={buffer}
        dotsState={dotsState}
        placeBid={placeBid}
        seatToNamesState={seatToNamesState}
        audio={audio}
      />
      <TopRight pageHeight={effectiveHeight} buffer={buffer} />
      <Chat
        pageHeight={effectiveHeight}
        buffer={buffer}
        messageState={messageState}
        sendMessage={sendMessage}
        playerName={playerName}
      />
      {/* <div
        style={{
          top: "80vh",
          left: 5,
          width: 10,
          height: 10,
          borderRadius: 5,
          backgroundColor: "red",
          position: "absolute",
          color: "red",
        }}
      ></div>

      <div
        style={{
          top: "80vh",
          left: 5 + 15,
          width: 10,
          height: 10,
          borderRadius: 5,
          backgroundColor: "red",
          position: "absolute",
          color: "red",
        }}
      ></div>

      <div
        style={{
          top: "80vh",
          left: 5 + 15 * 2,
          width: 10,
          height: 10,
          borderRadius: 5,
          backgroundColor: "red",
          position: "absolute",
          color: "red",
        }}
      ></div>

      <div
        style={{
          top: "80vh",
          left: 5 + 15 * 3,
          width: 10,
          height: 10,
          borderRadius: 5,
          backgroundColor: "red",
          position: "absolute",
          color: "red",
        }}
      ></div> */}
      <StateManager
        socket={socket}
        stateState={stateState}
        needSyncState={needSyncState}
        messageState={messageState}
        seatToNamesState={seatToNamesState}
        videoSate0={videoSate0}
        videoSate1={videoSate1}
        videoSate2={videoSate2}
        videoSate3={videoSate3}
        dotsState={dotsState}
        positionToVideoState={positionToVideoState}
        setErrorOccured={setErrorOccured}
        sendVideo={sendVideo}
        sendAudio={sendAudio}
        showSelf={showSelf}
        allowPlayState={allowPlayState}
      />
      <CardStateHelper
        allowPlayState={allowPlayState}
        stateState={stateState}
        needSyncState={needSyncState}
        cardState0={cardState0}
        cardState1={cardState1}
        cardState2={cardState2}
        cardState3={cardState3}
        cardState4={cardState4}
        cardState5={cardState5}
        cardState6={cardState6}
        cardState7={cardState7}
        cardState8={cardState8}
        cardState9={cardState9}
        cardState10={cardState10}
        cardState11={cardState11}
        cardState12={cardState12}
        cardState13={cardState13}
        cardState14={cardState14}
        cardState15={cardState15}
        cardState16={cardState16}
        cardState17={cardState17}
        cardState18={cardState18}
        cardState19={cardState19}
        cardState20={cardState20}
        cardState21={cardState21}
        cardState22={cardState22}
        cardState23={cardState23}
        cardState24={cardState24}
        cardState25={cardState25}
        cardState26={cardState26}
        cardState27={cardState27}
        cardState28={cardState28}
        cardState29={cardState29}
        cardState30={cardState30}
        cardState31={cardState31}
        cardState32={cardState32}
        cardState33={cardState33}
        cardState34={cardState34}
        cardState35={cardState35}
        cardState36={cardState36}
        cardState37={cardState37}
        cardState38={cardState38}
        cardState39={cardState39}
        cardState40={cardState40}
        cardState41={cardState41}
        cardState42={cardState42}
        cardState43={cardState43}
        cardState44={cardState44}
        cardState45={cardState45}
        cardState46={cardState46}
        cardState47={cardState47}
        cardState48={cardState48}
        cardState49={cardState49}
        cardState50={cardState50}
        cardState51={cardState51}
      ></CardStateHelper>
      <AllButNames />
    </div>
  );

  return dashboard;
}

export default App;
