import React from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";

function handleEnter(event) {
  if (event.keyCode === 13) {
    const form = event.target.form;
    const index = Array.prototype.indexOf.call(form, event.target);
    form.elements[index + 1].focus();
    event.preventDefault();
  }
}

export function Email({ emailState, sendEmail, emailThankYouState }) {
  // const seatToNames = useRecoilValue(seatToNamesState);
  const [showEmail, setEmail] = useRecoilState(emailState);

  const [input, setInput] = useState(""); // '' is the initial state value
  const [fromEmail, setFromEmail] = useState(""); // '' is the initial state value
  const setEmailThankYou = useSetRecoilState(emailThankYouState);

  return (
    <Modal
      // shouldCloseOnOverlayClick={false}
      animation={false}
      show={showEmail}
      onHide={() => {
        if (input === "" && fromEmail === "") {
          setEmail(false);
        }
      }}
    >
      <Modal.Header>
        <Modal.Title>Contact SpadesForHumanity</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="w-1sfsd00">
          <p style={{ color: "grey" }}>
            Prefer email? Send a message to spadesforhumanity@gmail.com
          </p>
          <Form.Group>
            <Form.Label>Your Email (optional)</Form.Label>
            <Form.Control
              value={fromEmail}
              onInput={(e) => setFromEmail(e.target.value)}
              as="input"
              onKeyDown={handleEnter}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Message</Form.Label>
            <Form.Control
              value={input}
              onInput={(e) => setInput(e.target.value)}
              as="textarea"
              rows="10"
              required
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setInput("");
            setFromEmail("");
            setEmail(false);
          }}
        >
          Close
        </Button>

        <Button
          type="submit"
          className="mr-2"
          onClick={() => {
            console.log("Send email");
            console.log(input);
            sendEmail(fromEmail, input);
            setInput("");
            setFromEmail("");
            setEmail(false);
            setEmailThankYou(true);
            // handleCloseEmail();
          }}
        >
          Send
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
