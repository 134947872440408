import React, { useRef } from "react";
import { Container, Form, Button } from "react-bootstrap";
import screenshot from "../images/Screenshot_2021-10-11.png";
import { isMobile } from "react-device-detect";

// import { v4 as uuidV4 } from "uuid";

export default function Home({
  onIdSubmit,
  playerName,
  setTargetpath,
  targetPath,
  homeCursorPosition,
  setHomeCursorPosition,
  errorOccured,
  setErrorOccured,
  setShowHome,
  playerNameTyped,
  setPlayerNameTyped,
  cursorOnPlayer,
  setCursorOnPlayer,
}) {
  const idRef = useRef();
  const roomRef = useRef();

  function handleSubmit(e) {
    e.preventDefault();
    let newTargetPath = "/" + roomRef.current.value;
    onIdSubmit(idRef.current.value);
    setTargetpath(newTargetPath);
    setShowHome(false);

    if (errorOccured !== false) {
      setErrorOccured(false);
      console.log("RELOADING");
      window.location.reload();
    }
  }

  //   function createNewId() {
  //     onIdSubmit(uuidV4());
  //   }

  let defaultPath = targetPath.substring(1);

  return (
    <div
      style={{
        color: "white",
        textAlign: "center",
      }}
    >
      <div style={{ height: "3vh" }}></div>
      <h1 style={{ fontSize: isMobile ? "3vh" : "5vh" }}>
        Spades for Humanity
      </h1>
      <div style={{ height: "5vh" }}></div>

      <Container
        className="align-items-center flex"
        style={{
          minWidth: "300px",
          width: "30vw",
          border: "solid white 2px",
          padding: "1vw",
          background: "darkgreen",
        }}
      >
        <h2
          style={{
            fontSize: isMobile ? "3vh" : "2vh",
          }}
        >
          CREATE OR JOIN A ROOM
        </h2>
        <Form onSubmit={handleSubmit} className="w-100">
          <div style={{ color: "red" }}>{errorOccured}</div>
          <div style={{ height: "3vh" }}></div>

          <Form.Group>
            <Form.Label>Room name </Form.Label>
            <Form.Control
              maxLength={20}
              type="text"
              ref={roomRef}
              required
              autoFocus={!cursorOnPlayer}
              defaultValue={defaultPath}
              onChange={() => {
                setHomeCursorPosition(roomRef.current.selectionStart);
                setTargetpath("/" + roomRef.current.value);
                setCursorOnPlayer(false);
              }}
              onFocus={(e) =>
                e.currentTarget.setSelectionRange(
                  homeCursorPosition,
                  homeCursorPosition
                )
              }
            />
          </Form.Group>
          <div style={{ height: "3vh" }}></div>

          <Form.Group>
            <Form.Label>Player name</Form.Label>
            <Form.Control
              maxLength={10}
              type="text"
              ref={idRef}
              required
              autoFocus={cursorOnPlayer}
              defaultValue={playerNameTyped}
              onChange={() => {
                setHomeCursorPosition(idRef.current.selectionStart);
                setPlayerNameTyped(idRef.current.value);
                setCursorOnPlayer(true);
              }}
              onFocus={(e) =>
                e.currentTarget.setSelectionRange(
                  homeCursorPosition,
                  homeCursorPosition
                )
              }
            />
          </Form.Group>
          <div style={{ height: "1vh" }}></div>

          <Button type="submit" className="mr-2">
            Login*
          </Button>
          {/* <Button onClick={createNewId} variant="secondary">
          Create A New Id
        </Button> */}
          {/* <div style={{ height: "5vh" }}></div> */}
        </Form>
        <div style={{ height: "1vh" }}></div>
        <h4
          style={{
            fontSize: "1.2vh",
          }}
        >
          *Logging in will store your player name in your browser's localstorage
          as well as your settings for SpadesForHumanity. Your player name can
          reset by clicking Logout in the menu. This website does not use
          cookies or share user information or analytics with any external
          party.
        </h4>
      </Container>

      <div style={{ height: "5vh" }}></div>

      <Container
        className="align-items-center flex"
        style={{
          width: "30vw",
          minWidth: "300px",
          border: "solid white 2px",
          padding: "1vw",
          background: "darkgreen",
        }}
      >
        <h2
          style={{
            fontSize: isMobile ? "3vh" : "2vh",
          }}
        >
          AN EXAMPLE GAME
        </h2>
        <div style={{ height: "1vh" }}></div>

        <img
          style={{
            width: "25vw",
            minWidth: "250px",
            height: "13.3vw",
            minHeight: "133px",
            border: "5px solid lightgreen",
          }}
          src={screenshot}
          alt="Screenshot loading."
        ></img>
      </Container>

      <div style={{ height: "5vh" }}></div>

      <Container
        // className="align-items-center flex"
        style={{
          width: "50vw",
          minWidth: "300px",
          border: "solid white 2px",
          padding: "1vw",
          background: "darkGreen",
          fontSize: "1.8vh",
        }}
      >
        <h2
          style={{
            fontSize: isMobile ? "3vh" : "2vh",
          }}
        >
          FEATURES
        </h2>
        <ul
          style={{
            textAlign: "left",
            // maxWidth: "45vw",
            paddingLeft: "3vw",
            paddingRight: "2vw",
          }}
        >
          <li>Play the card game spades with friends or against computers.</li>
          <li>Use audio if you'd like to hear each other.</li>
          <li>
            Use video if you'd like to see each other. (Or see yourself if
            you're playing alone, as demonstrated above)
          </li>
          <li>
            Video and audio streams are peer to peer encryped between the
            players in the room.
          </li>
        </ul>
      </Container>

      <div style={{ height: "10vh" }}></div>
    </div>
  );
}
