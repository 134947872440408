import { useEffect, useState } from "react";

const PREFIX = "spades-for-humanity-";

export default function useLocalStorage(key, initialValue) {
  const prefiedKey = PREFIX + key;

  const [value, setValue] = useState(() => {
    const jsoneValue = localStorage.getItem(prefiedKey);
    if (jsoneValue != null) return JSON.parse(jsoneValue);
    if (typeof initialValue == "function") {
      return initialValue();
    } else {
      return initialValue;
    }
  });

  useEffect(() => {
    if (value != null) {
      localStorage.setItem(prefiedKey, JSON.stringify(value));
    }
  }, [prefiedKey, value]);

  return [value, setValue];
}
