import ReactTooltip from "react-tooltip";
import { useRecoilValue } from "recoil";

export function TopProgressBar({
  buffer,
  allocatedHeight,
  startingHeight,
  dotsState,
}) {
  let padding = buffer * 0.05;
  let barHeight = allocatedHeight / 4;
  let fontSize = `${0.15 * allocatedHeight}px`;

  let barWidth = buffer - barHeight - 2 * padding;

  const dots = useRecoilValue(dotsState);

  // let boxShadow = "3px 2px 15px 1px #4f5951";
  let boxShadow = "1px 1px 15px 1px #4f5951";

  if (!(dots && dots.firstScore)) {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          height: allocatedHeight,
          width: buffer,
          backgroundColor: "darkgreen",
          padding: padding,
        }}
      ></div>
    );
  }

  return (
    <div
      id="topprogressbar"
      style={{
        position: "absolute",
        top: 0,
        height: allocatedHeight,
        width: buffer,
        backgroundColor: "darkgreen",
        padding: padding,
        zIndex: 90,
      }}
    >
      {dots.firstScore.amountFilled > 0 && (
        <div
          id="firstScoreid"
          data-tip
          data-for="firstScore"
          style={{
            position: "absolute",
            top: allocatedHeight / 6,
            backgroundColor: dots.firstScore.fillColor,
            height: barHeight,
            width: barWidth * dots.firstScore.amountFilled,
            borderRadius: allocatedHeight / 2,
            border: `2px solid ${dots.firstScore.borderColor}`,
            margin: 0,
            padding: 0,
            zIndex: 1,
            transition: "width 1s ease-in",
          }}
        />
      )}

      <div
        id="firstScoreBid"
        style={{
          position: "absolute",
          top: allocatedHeight / 6,
          height: barHeight,
          width: barWidth,
          borderRadius: allocatedHeight / 2,
          border: `2px solid ${dots.firstScore.borderColor}`,
          backgroundColor: "green",
          margin: 0,
          padding: 0,
          zIndex: 0,
          boxShadow: boxShadow,
        }}
      />

      <div
        data-tip
        data-for="firstScoreB"
        style={{
          position: "absolute",
          top: allocatedHeight / 6,
          height: barHeight,
          width: barWidth,
          borderRadius: allocatedHeight / 2,
          border: `2px solid ${dots.firstScore.borderColor}`,
          margin: 0,
          padding: 0,
          zIndex: 2,
          textAlign: "center",
          color: "white",
          fontSize: fontSize,
          cursor: "default",
          userSelect: "none",
        }}
      >
        {dots.firstScore.score}
      </div>

      <div
        id="firstBooksid"
        data-tip
        data-for="firstBooks"
        style={{
          position: "absolute",
          top: allocatedHeight / 6,
          left: barWidth + 1.2 * padding,
          height: barHeight,
          width: barHeight,
          borderRadius: allocatedHeight / 2,
          border: `2px solid ${dots.firstScore.borderColor}`,
          backgroundColor: "green",
          margin: 0,
          padding: 0,
          zIndex: 0,
          textAlign: "center",
          color: "white",
          fontSize: fontSize,
          boxShadow: boxShadow,
          cursor: "default",
          userSelect: "none",
        }}
      >
        {dots.firstScore.books}
      </div>

      <div style={{ zIndex: 990000999 }}>
        <ReactTooltip id="firstScore">
          <span style={{ zIndex: 99999 }}>{dots.firstScore.text}</span>
        </ReactTooltip>
      </div>

      <ReactTooltip id="firstScoreB">
        <span style={{ zIndex: 99999 }}>{dots.firstScore.text}</span>
      </ReactTooltip>

      <ReactTooltip id="firstBooks">
        <span style={{ zIndex: 999 }}>{dots.firstScore.bookText}</span>
      </ReactTooltip>

      {dots.secondScore.amountFilled > 0 && (
        <div
          id="secondScore123id"
          data-tip
          data-for="secondScore123"
          style={{
            top: allocatedHeight / 3 + barHeight,
            position: "absolute",
            backgroundColor: dots.secondScore.fillColor,
            height: barHeight,
            width: barWidth * dots.secondScore.amountFilled,
            borderRadius: allocatedHeight / 2,
            border: `2px solid ${dots.secondScore.borderColor}`,
            // zIndex: 9999999,
            margin: 0,
            padding: 0,
            zIndex: 1,
            transition: "width 1s ease-in",
          }}
        />
      )}

      <div
        id="secondScoreBid"
        style={{
          top: allocatedHeight / 3 + barHeight,
          position: "absolute",
          height: barHeight,
          width: barWidth,
          borderRadius: allocatedHeight / 2,
          border: `2px solid ${dots.secondScore.borderColor}`,
          backgroundColor: "green",
          margin: 0,
          padding: 0,
          zIndex: 0,
          boxShadow: boxShadow,
        }}
      />

      <div
        data-tip
        data-for="secondScoreB"
        style={{
          top: allocatedHeight / 3 + barHeight,
          position: "absolute",
          height: barHeight,
          width: barWidth,
          borderRadius: allocatedHeight / 2,
          border: `2px solid ${dots.secondScore.borderColor}`,
          margin: 0,
          padding: 0,
          zIndex: 51,
          // zIndex: 2,
          textAlign: "center",
          color: "white",
          fontSize: fontSize,
          cursor: "default",
          userSelect: "none",
        }}
      >
        {dots.secondScore.score}
      </div>

      <div
        id="secondBooksID"
        data-tip
        data-for="secondBooks"
        style={{
          position: "absolute",
          top: allocatedHeight / 3 + barHeight,
          left: barWidth + 1.2 * padding,
          height: barHeight,
          width: barHeight,
          borderRadius: allocatedHeight / 2,
          border: `2px solid ${dots.secondScore.borderColor}`,
          backgroundColor: "green",
          margin: 0,
          padding: 0,
          zIndex: 50,
          textAlign: "center",
          color: "white",
          fontSize: fontSize,
          boxShadow: boxShadow,
          cursor: "default",
          userSelect: "none",
        }}
      >
        {dots.secondScore.books}
      </div>

      <div style={{ zIndex: 990000999 }}>
        <ReactTooltip id="secondScore123">
          <span style={{ zIndex: 99999 }}>{dots.secondScore.text}</span>
        </ReactTooltip>
      </div>

      <ReactTooltip id="secondScoreB">
        <span style={{ zIndex: 99999 }}>{dots.secondScore.text}</span>
      </ReactTooltip>

      <div style={{ zIndex: 99 }}>
        <ReactTooltip id="secondBooks">
          <span style={{ zIndex: 99 }}>{dots.secondScore.bookText}</span>
        </ReactTooltip>
      </div>
    </div>
  );
}
