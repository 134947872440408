export const defaultCards = [
  {
    handPosition: 0,
    player: 0,
    rank: 5,
    suit: "d",
  },
  {
    handPosition: 1,
    player: 0,
    rank: 6,
    suit: "s",
  },
  {
    handPosition: 2,
    player: 0,
    rank: 9,
    suit: "s",
  },
  {
    handPosition: 3,
    player: 0,
    rank: 13,
    suit: "d",
  },
  {
    handPosition: 4,
    player: 0,
    rank: 14,
    suit: "s",
  },
  {
    handPosition: 5,
    player: 0,
    rank: 10,
    suit: "h",
  },
  {
    handPosition: 6,
    player: 0,
    rank: 9,
    suit: "h",
  },
  {
    handPosition: 7,
    player: 0,
    rank: 11,
    suit: "h",
  },
  {
    handPosition: 8,
    player: 0,
    rank: 2,
    suit: "c",
  },
  {
    handPosition: 9,
    player: 0,
    rank: 5,
    suit: "c",
  },
  {
    handPosition: 10,
    player: 0,
    rank: 2,
    suit: "h",
  },
  {
    handPosition: 11,
    player: 0,
    rank: 7,
    suit: "c",
  },
  {
    handPosition: 12,
    player: 0,
    rank: 4,
    suit: "d",
  },
  {
    handPosition: 0,
    player: 1,
    rank: 8,
    suit: "h",
  },
  {
    handPosition: 1,
    player: 1,
    rank: 4,
    suit: "h",
  },
  {
    handPosition: 2,
    player: 1,
    rank: 7,
    suit: "d",
  },
  {
    handPosition: 3,
    player: 1,
    rank: 5,
    suit: "s",
  },
  {
    handPosition: 4,
    player: 1,
    rank: 2,
    suit: "s",
  },
  {
    handPosition: 5,
    player: 1,
    rank: 6,
    suit: "c",
  },
  {
    handPosition: 6,
    player: 1,
    rank: 11,
    suit: "c",
  },
  {
    handPosition: 7,
    player: 1,
    rank: 7,
    suit: "h",
  },
  {
    handPosition: 8,
    player: 1,
    rank: 13,
    suit: "s",
  },
  {
    handPosition: 9,
    player: 1,
    rank: 14,
    suit: "d",
  },
  {
    handPosition: 10,
    player: 1,
    rank: 3,
    suit: "c",
  },
  {
    handPosition: 11,
    player: 1,
    rank: 8,
    suit: "s",
  },
  {
    handPosition: 12,
    player: 1,
    rank: 7,
    suit: "s",
  },
  {
    handPosition: 0,
    player: 2,
    rank: 13,
    suit: "h",
  },
  {
    handPosition: 1,
    player: 2,
    rank: 4,
    suit: "c",
  },
  {
    handPosition: 2,
    player: 2,
    rank: 3,
    suit: "s",
  },
  {
    handPosition: 3,
    player: 2,
    rank: 5,
    suit: "h",
  },
  {
    handPosition: 4,
    player: 2,
    rank: 9,
    suit: "d",
  },
  {
    handPosition: 5,
    player: 2,
    rank: 2,
    suit: "d",
  },
  {
    handPosition: 6,
    player: 2,
    rank: 12,
    suit: "s",
  },
  {
    handPosition: 7,
    player: 2,
    rank: 14,
    suit: "c",
  },
  {
    handPosition: 8,
    player: 2,
    rank: 3,
    suit: "h",
  },
  {
    handPosition: 9,
    player: 2,
    rank: 9,
    suit: "c",
  },
  {
    handPosition: 10,
    player: 2,
    rank: 12,
    suit: "h",
  },
  {
    handPosition: 11,
    player: 2,
    rank: 11,
    suit: "d",
  },
  {
    handPosition: 12,
    player: 2,
    rank: 11,
    suit: "s",
  },
  {
    handPosition: 0,
    player: 3,
    rank: 6,
    suit: "h",
  },
  {
    handPosition: 1,
    player: 3,
    rank: 4,
    suit: "s",
  },
  {
    handPosition: 2,
    player: 3,
    rank: 14,
    suit: "h",
  },
  {
    handPosition: 3,
    player: 3,
    rank: 3,
    suit: "d",
  },
  {
    handPosition: 4,
    player: 3,
    rank: 8,
    suit: "c",
  },
  {
    handPosition: 5,
    player: 3,
    rank: 6,
    suit: "d",
  },
  {
    handPosition: 6,
    player: 3,
    rank: 12,
    suit: "c",
  },
  {
    handPosition: 7,
    player: 3,
    rank: 13,
    suit: "c",
  },
  {
    handPosition: 8,
    player: 3,
    rank: 8,
    suit: "d",
  },
  {
    handPosition: 9,
    player: 3,
    rank: 12,
    suit: "d",
  },
  {
    handPosition: 10,
    player: 3,
    rank: 10,
    suit: "d",
  },
  {
    handPosition: 11,
    player: 3,
    rank: 10,
    suit: "s",
  },
  {
    handPosition: 12,
    player: 3,
    rank: 10,
    suit: "c",
  },
];
