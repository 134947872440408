import React from "react";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import { useState } from "react";
import { useRecoilState } from "recoil";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";

import RangeSlider from "react-bootstrap-range-slider";

export function Settings({ settingsState, appVolume, setAppVolume }) {
  // const seatToNames = useRecoilValue(seatToNamesState);
  const [settings, setSettings] = useRecoilState(settingsState);
  const [gameVolume, setGameVolume] = useState(settings.effectsVolume);
  const [showSelf, setShowSelf] = useState(settings.showSelf);
  const [sendVideo, setSendVideo] = useState(settings.sendVideo);
  const [sendAudio, setSendAudio] = useState(settings.sendAudio);

  return (
    <Modal
      // shouldCloseOnOverlayClick={false}
      animation={false}
      show={settings.show}
      onHide={() => {
        if (settings.effectsVolume !== gameVolume) {
          console.log("volume mismatch");
          console.log(settings.effectsVolume);
          console.log(gameVolume);

          return;
        }
        if (settings.showSelf !== showSelf) {
          console.log("show self mismatch");

          return;
        }
        if (settings.sendVideo !== sendVideo) {
          console.log("sendVideo mismatch");

          return;
        }

        if (settings.sendAudio !== sendAudio) {
          console.log("sendAudio mismatch");

          return;
        }

        setSettings({
          effectsVolume: settings.effectsVolume,
          showSelf: showSelf,
          sendVideo: sendVideo,
          sendAudio: sendAudio,
          show: false,
        });
      }}
    >
      <Modal.Header>
        <Modal.Title>Settings</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="w-1sfsd00">
          <div className="custom-control custom-checkbox" id="23fsdfsdf">
            <input
              type="checkbox"
              className="custom-control-input"
              id="setSendAudio"
              checked={sendAudio}
              onChange={(e) => {
                setSendAudio(!sendAudio);
              }}
            />
            <label className="custom-control-label" htmlFor="setSendAudio">
              Send audio
            </label>
          </div>

          <div className="custom-control custom-checkbox" id="sdfsdf">
            <input
              type="checkbox"
              className="custom-control-input"
              id="setSendVideo"
              checked={sendVideo}
              onChange={(e) => {
                setSendVideo(!sendVideo);
              }}
            />
            <label className="custom-control-label" htmlFor="setSendVideo">
              Send video
            </label>
          </div>

          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="defaultUnchecked"
              checked={showSelf && sendVideo}
              onChange={(e) => {
                setShowSelf(!showSelf);
              }}
              disabled={sendVideo === false}
            />
            <label className="custom-control-label" htmlFor="defaultUnchecked">
              View my video
            </label>
          </div>

          <Form.Group as={Row}>
            <Form.Label column sm="4">
              Effects Volume
            </Form.Label>
            <Col sm="8">
              <RangeSlider
                value={gameVolume}
                onChange={(e) => setGameVolume(e.target.value)}
              />
            </Col>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setGameVolume(settings.effectsVolume);
            setShowSelf(settings.showSelf);
            setSendVideo(settings.sendVideo);
            setSendAudio(settings.sendAudio);
            setSettings({ ...settings, show: false });
          }}
        >
          Cancel
        </Button>

        <Button
          type="submit"
          className="mr-2"
          onClick={() => {
            console.log({
              showSelf: showSelf,
              sendAudio: sendAudio,
              sendVideo: sendVideo,
              show: false,
            });
            // setSettings({
            //   showSelf: showSelf,
            //   useVideo: useVideo,
            //   show: false,
            // });

            console.log("HEREEEEEEEEEEEEEEEEEEEEEEEEE");
            if (gameVolume !== appVolume) {
              console.log("SETTNIG APP VOLUE");
              setAppVolume(gameVolume);
              localStorage.setItem(
                "spadesforhumanity.com-effectsVolume",
                JSON.stringify(gameVolume)
              );
              setSettings({
                ...settings,
                effectsVolume: gameVolume,
                show: false,
              });
            }
            // localStorage.setItem("spadesforhumanity.com-sendAndSeeVideo", JSON.stringify(value));

            if (
              settings.sendAudio !== sendAudio ||
              settings.sendVideo !== sendVideo ||
              settings.showSelf !== showSelf
            ) {
              localStorage.setItem(
                "spadesforhumanity.com-sendAudio",
                JSON.stringify(sendAudio)
              );
              localStorage.setItem(
                "spadesforhumanity.com-sendVideo",
                JSON.stringify(sendVideo)
              );
              localStorage.setItem(
                "spadesforhumanity.com-showSelf",
                JSON.stringify(showSelf)
              );
              console.log("RELOADING");
              window.location.reload();
            }
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
