import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useRecoilState } from "recoil";

// function handleEnter(event) {
//   if (event.keyCode === 13) {
//     const form = event.target.form;
//     const index = Array.prototype.indexOf.call(form, event.target);
//     form.elements[index + 1].focus();
//     event.preventDefault();
//   }
// }

export function EmailThankYou({ emailThankYouState }) {
  const [emailThankYou, setEmailThankYou] = useRecoilState(emailThankYouState);
  return (
    <Modal
      // shouldCloseOnOverlayClick={false}
      animation={false}
      show={emailThankYou}
      onHide={() => {
        setEmailThankYou(false);
      }}
    >
      <Modal.Header>
        <Modal.Title>Thanks!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>We've recieved you message :)</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setEmailThankYou(false);
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
