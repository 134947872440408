import React, { useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { Row } from "react-bootstrap";

const loadingColor = "#004500";
const lightGreen = "#b3ffb3";

function Dots({
  videoHeight,
  videoWidth,
  padding,
  horizontalPadding,
  dotsState,
  commonStyle,
  pageHeight,
}) {
  const dots = useRecoilValue(dotsState);

  let fontSize = `${0.03 * pageHeight}px`;
  let drawCiclre = ({ k, color }) => {
    if (color === "yellow") {
      return (
        <div
          key={k + color}
          style={{
            zIndex: 500,
            height: videoHeight * 0.042,
            width: videoHeight * 0.042,
            borderRadius: videoHeight * 0.042,
            marginRight: videoHeight * 0.01,
            marginTop: videoHeight * 0.02,
            border: `solid ${lightGreen} 2px`,
            // backgroundColor: "#a3ffa3",
            boxShadow: `1px 1px 5px 2px #008000`,
          }}
        ></div>
      );
    }
    return (
      <div
        key={k + color}
        style={{
          backgroundColor: color,
          zIndex: 990,
          height: videoHeight * 0.042,
          width: videoHeight * 0.042,
          borderRadius: videoHeight * 0.042,
          marginRight: videoHeight * 0.01,
          marginTop: videoHeight * 0.02,
        }}
      ></div>
    );
  };

  let dsStyle = {
    width: videoWidth,
    height: videoHeight * 0.05,
    position: "absolute",
    // backgroundColor: "red",
    display: "flex",
    flexDirection: "row",
    zIndex: 900,
  };

  let dsStyle0 = {
    ...dsStyle,
    top: videoHeight + 2 * padding + videoHeight,
    left: videoWidth + 2 * padding + 2 * horizontalPadding + videoHeight * 0.1,
  };

  // Bottom left
  let dsStyle1 = {
    ...dsStyle,
    top: videoHeight + 2 * padding + videoHeight,
    left: padding + horizontalPadding + videoHeight * 0.1,
  };

  let dsStyle2 = {
    ...dsStyle,
    top: padding + videoHeight,
    left: padding + horizontalPadding + videoHeight * 0.1,
  };

  let dsStyle3 = {
    ...dsStyle,

    top: padding + videoHeight,
    left: videoWidth + 2 * padding + 2 * horizontalPadding + videoHeight * 0.1,
  };

  return (
    <div
      style={{
        fontSize: fontSize,
        // textDecorationLine: "underline",
        fontWeight: "bold",
        textShadow: "#9E9E9E 0px 0px 5px",
      }}
    >
      {/* <div
        style={{
          ...dsStyle1,
          top: dsStyle1.top - videoHeight * 0.5,
          zIndex: 10002,
          color: "white",
          fontSize: `${0.1 * videoHeight}px`,
        }}
      >
        {dots[1]["name"]}
      </div> */}
      <div
        // Bottom left
        style={{
          ...commonStyle,
          top: videoHeight + 2 * padding,
          left: padding + horizontalPadding,
          border: `solid ${dots[1]["color"]} ${Math.floor(
            dots[1]["borderScale"] * pageHeight
          )}px`,
          zIndex: 700,
          pointerEvents: "none",
        }}
        key={"videoContainRRCircle"}
      ></div>
      <Row key="1yellow" style={dsStyle1}>
        {Array(dots[1]["bid"])
          .fill(0)
          .map((_, k) => {
            return drawCiclre({
              k: "1yellow" + k,
              color: "yellow",
            });
          })}
      </Row>
      <Row key="1red" style={dsStyle1}>
        <p
          style={{
            color: dots[1]["taken"] > 0 ? "red" : lightGreen,
            marginRight: dots[1]["nillBidText"] === "" ? "" : "10px",
            // textDecorationLine: "underline",
          }}
        >
          {dots[1]["nillBidText"] === "" ? "" : dots[1]["nillBidText"]}
        </p>

        {Array(dots[1]["taken"])
          .fill(0)
          .map((_, k) => {
            return drawCiclre({
              k: "1red" + k,
              color: "red",
            });
          })}
      </Row>
      <Row key="1purple" style={dsStyle1}>
        {Array(Math.min(dots[1]["taken"], dots[1]["bid"]))
          .fill(0)
          .map((_, k) => {
            return drawCiclre({
              k: "1purple" + k,
              color: dots[1]["color"],
            });
          })}
      </Row>
      <div
        style={{
          ...commonStyle,
          top: padding,
          left: videoWidth + 2 * padding + 2 * horizontalPadding,
          border: `solid ${dots[3]["color"]} ${Math.floor(
            dots[3]["borderScale"] * pageHeight
          )}px`,
          zIndex: 200,
          pointerEvents: "none",
        }}
        key={"top-right-highlight"}
      ></div>
      <Row key="3yellow" style={dsStyle3}>
        {Array(dots[3]["bid"])
          .fill(0)
          .map((_, k) => {
            return drawCiclre({
              k: "3yellow" + k,
              color: "yellow",
            });
          })}
      </Row>
      <Row key="3red" style={dsStyle3}>
        <p
          style={{
            color: dots[3]["taken"] > 0 ? "red" : lightGreen,
            marginRight: dots[3]["nillBidText"] === "" ? "" : "10px",
            // textDecorationLine: "underline",
          }}
        >
          {dots[3]["nillBidText"] === "" ? "" : dots[3]["nillBidText"]}
        </p>

        {Array(dots[3]["taken"])
          .fill(0)
          .map((_, k) => {
            return drawCiclre({
              k: "3red" + k,
              color: "red",
            });
          })}
      </Row>
      <Row key="3purple" style={dsStyle3}>
        {Array(Math.min(dots[3]["taken"], dots[3]["bid"]))
          .fill(0)
          .map((_, k) => {
            return drawCiclre({
              k: "3purple" + k,
              color: dots[3]["color"],
            });
          })}
      </Row>
      <div
        style={{
          ...commonStyle,
          top: padding,
          left: padding + horizontalPadding,
          border: `solid ${dots[2]["color"]} ${Math.floor(
            dots[2]["borderScale"] * pageHeight
          )}px`,
          zIndex: 200,
          pointerEvents: "none",
        }}
        onClick={() => {
          console.log("HIt me ");
        }}
        key={"top-left-highlight"}
      ></div>
      <Row key="2yellow" style={dsStyle2}>
        {Array(dots[2]["bid"])
          .fill(0)
          .map((_, k) => {
            return drawCiclre({
              k: "2yellow" + k,
              color: "yellow",
            });
          })}
      </Row>
      <Row key="2red" style={dsStyle2}>
        <p
          style={{
            color: dots[2]["taken"] > 0 ? "red" : lightGreen,
            marginRight: dots[2]["nillBidText"] === "" ? "" : "10px",
            // textDecorationLine: "underline",
          }}
        >
          {dots[2]["nillBidText"] === "" ? "" : dots[2]["nillBidText"]}
        </p>

        {Array(dots[2]["taken"])
          .fill(0)
          .map((_, k) => {
            return drawCiclre({
              k: "2red" + k,
              color: "red",
            });
          })}
      </Row>
      <Row key="2purple" style={dsStyle2}>
        {Array(Math.min(dots[2]["taken"], dots[2]["bid"]))
          .fill(0)
          .map((_, k) => {
            return drawCiclre({
              k: "2purple" + k,
              color: dots[2]["color"],
            });
          })}
      </Row>
      <div
        // bottom Right highlight
        // className="double-border"
        style={{
          ...commonStyle,
          top: videoHeight + 2 * padding,
          left: videoWidth + 2 * padding + 2 * horizontalPadding,
          border: `solid ${dots[0]["color"]} ${Math.floor(
            dots[0]["borderScale"] * pageHeight
          )}px`,
          zIndex: 200,
          pointerEvents: "none",
        }}
        key={"videoContainRCircle"}
      ></div>
      <Row key="0yellow" style={dsStyle0}>
        {Array(dots[0]["bid"])
          .fill(0)
          .map((_, k) => {
            return drawCiclre({
              k: "0yellow" + k,
              color: "yellow",
            });
          })}
      </Row>
      <Row key="0red" style={dsStyle0}>
        <p
          style={{
            color: dots[0]["taken"] > 0 ? "red" : lightGreen,
            marginRight: dots[0]["nillBidText"] === "" ? "" : "10px",
            // textDecorationLine: "underline",
            fontWeight: "bold",
          }}
        >
          {/* dfd */}
          {dots[0]["nillBidText"] === "" ? "" : dots[0]["nillBidText"]}
        </p>
        {Array(dots[0]["taken"])
          .fill(0)
          .map((_, k) => {
            return drawCiclre({
              k: "0red" + k,
              color: "red",
            });
          })}
      </Row>
      <Row key="0purple" style={dsStyle0}>
        {Array(Math.min(dots[0]["taken"], dots[0]["bid"]))
          .fill(0)
          .map((_, k) => {
            return drawCiclre({
              k: "0purple" + k,
              color: dots[0]["color"],
            });
          })}
      </Row>
      {/* <Row key="0white" style={dsStyle0}>
        {Array(dots[0]["white"] * 13)
          .fill(0)
          .map((_, k) => {
            return drawCiclre({
              k: "0white" + k,
              color: "white",
            });
          })}
      </Row> */}
    </div>
  );
}

// function MyVideo0({ ref0, videoStyle }) {
//   console.log("THIS IS BAD!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
//   return (
//     <video
//       style={{
//         ...videoStyle,
//         width: hoverBottomRight ? videoWidth * 3 : "",
//       }}
//       playsInline
//       ref={ref0}
//       autoPlay
//       key={"videoR"}
//       id="videoID0"
//       controls={true}
//     />
//   );
// }

export function MyVideo({
  videoSate0,
  videoSate1,
  videoSate2,
  videoSate3,
  socket,
  pageHeight,
  buffer,
  dotsState,
  positionToVideoState,
  progressHeight,
}) {
  const ref3 = useRef();
  const ref2 = useRef();
  const ref1 = useRef();
  const ref0 = useRef();

  const video0 = useRecoilValue(videoSate0);
  const video1 = useRecoilValue(videoSate1);
  const video2 = useRecoilValue(videoSate2);
  const video3 = useRecoilValue(videoSate3);

  const [hoverBottomRight, setHoverBottomRight] = useState(false);
  const [hoverBottomLeft, setHoverBottomLeft] = useState(false);
  const [hoverTopRight, setHoverTopRight] = useState(false);
  const [hoverTopLeft, setHoverTopLeft] = useState(false);

  const positionToVideo = useRecoilValue(positionToVideoState);

  console.log("In Video");

  if (video0 || video1 || video2 || video3) {
    try {
      ref0.current.srcObject = null;
      ref1.current.srcObject = null;
      ref2.current.srcObject = null;
      ref3.current.srcObject = null;

      if (positionToVideo.p0 === 0) {
        ref0.current.srcObject = video0 || null;
      }
      if (positionToVideo.p0 === 1) {
        ref1.current.srcObject = video0 || null;
      }
      if (positionToVideo.p0 === 2) {
        ref2.current.srcObject = video0 || null;
      }
      if (positionToVideo.p0 === 3) {
        ref3.current.srcObject = video0 || null;
      }

      if (positionToVideo.p1 === 0) {
        ref0.current.srcObject = video1 || null;
      }
      if (positionToVideo.p1 === 1) {
        ref1.current.srcObject = video1 || null;
      }
      if (positionToVideo.p1 === 2) {
        ref2.current.srcObject = video1 || null;
      }
      if (positionToVideo.p1 === 3) {
        ref3.current.srcObject = video1 || null;
      }

      if (positionToVideo.p2 === 0) {
        ref0.current.srcObject = video2 || null;
      }
      if (positionToVideo.p2 === 1) {
        ref1.current.srcObject = video2 || null;
      }
      if (positionToVideo.p2 === 2) {
        ref2.current.srcObject = video2 || null;
      }
      if (positionToVideo.p2 === 3) {
        ref3.current.srcObject = video2 || null;
      }

      if (positionToVideo.p3 === 0) {
        ref0.current.srcObject = video3 || null;
      }
      if (positionToVideo.p3 === 1) {
        ref1.current.srcObject = video3 || null;
      }
      if (positionToVideo.p3 === 2) {
        ref2.current.srcObject = video3 || null;
      }
      if (positionToVideo.p3 === 3) {
        ref3.current.srcObject = video3 || null;
      }
    } catch (e) {
      console.log(e);
    }
  }

  let padding = Math.floor(pageHeight * 0.04);
  let avaiableHeight = (pageHeight - 3 * padding) / 2;
  let avaiableWidth = (buffer - 3 * padding) / 2;
  let videoWidth = Math.floor(
    Math.min(avaiableWidth, (208 / 303) * avaiableHeight)
  );
  let videoHeight = Math.floor((303 / 208) * videoWidth);
  let horizontalWaste = Math.max(buffer - 2 * videoWidth - 3 * padding, 0);
  let horizontalPadding = Math.floor(horizontalWaste / 3);

  let commonStyle = {
    overflow: "hidden",
    position: "absolute",
    borderRadius: `5vh`,
    zIndex: 101,
    margin: 0,
    padding: 0,
    transform: "rotateY(180deg)",
    height: videoHeight,
    width: videoWidth,
    boxShadow: `-2px 2px 10px 1px #007a00`,
  };

  const videoStyle = {
    backgroundColor: loadingColor,
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    height: videoHeight,
    zIndex: 100,
    color: "white",
    textAlign: "center",
    margin: 0,
    padding: 0,
  };

  return (
    <div
      key={"allds"}
      style={{
        height: pageHeight,
        width: buffer,
        backgroundColor: "darkgreen",
        left: 0,
        top: progressHeight,
        position: "absolute",
        zIndex: 900,
        // border: "solid white",
        // borderRight: "solid white"
      }}
    >
      <Dots
        videoHeight={videoHeight}
        videoWidth={videoWidth}
        padding={padding}
        horizontalPadding={horizontalPadding}
        dotsState={dotsState}
        commonStyle={commonStyle}
        pageHeight={pageHeight}
      />

      <div
        // bottom Right
        style={{
          ...commonStyle,
          top: videoHeight + 2 * padding,
          left: videoWidth + 2 * padding + 2 * horizontalPadding,
          width: hoverBottomRight ? videoWidth * 1.9 : videoWidth,
          transform: hoverBottomRight ? "" : "rotateY(180deg)",
          zIndex: hoverBottomRight ? 1000 : 100,
          backgroundColor: loadingColor,
        }}
        id="videoContainRid"
        key={"videoContainR"}
        onMouseEnter={() => setHoverBottomRight(true)}
        onMouseLeave={() => setHoverBottomRight(false)}
      >
        <video
          style={{
            ...videoStyle,
            // width: hoverBottomRight ? videoWidth * 3 : "",
          }}
          playsInline
          ref={ref0}
          autoPlay
          key={"videoR"}
          id="videoID0"
          controls
        />{" "}
      </div>

      <div
        // Bottom left
        style={{
          ...commonStyle,
          top: videoHeight + 2 * padding,
          left: padding + horizontalPadding,
          width: hoverBottomLeft ? videoWidth * 1.9 : videoWidth,
          transform: hoverBottomLeft ? "" : "rotateY(180deg)",
          zIndex: hoverBottomLeft ? 900 : 100,
          backgroundColor: loadingColor,
        }}
        id="videoContainRRid"
        key={"videoContainRR"}
        onMouseEnter={() => setHoverBottomLeft(true)}
        onMouseLeave={() => setHoverBottomLeft(false)}
      >
        <video
          style={{
            ...videoStyle,
          }}
          playsInline
          ref={ref1}
          autoPlay
          key={"videoRR"}
          id="videoID1"
          controls
        />
      </div>

      <div
        // Top left
        style={{
          ...commonStyle,
          top: padding,
          left: padding + horizontalPadding,
          width: hoverTopLeft ? videoWidth * 1.9 : videoWidth,
          transform: hoverTopLeft ? "" : "rotateY(180deg)",
          zIndex: hoverTopLeft ? 900 : 100,
          backgroundColor: loadingColor,
        }}
        id="videoContainRRRid"
        key={"videoContainRRR"}
        onMouseEnter={() => setHoverTopLeft(true)}
        onMouseLeave={() => setHoverTopLeft(false)}
      >
        <video
          style={{
            ...videoStyle,
          }}
          playsInline
          ref={ref2}
          autoPlay
          key={"videoRRR"}
          id="videoID2"
          controls
        />
      </div>
      <div
        // Top right
        style={{
          ...commonStyle,
          top: padding,
          left: videoWidth + 2 * padding + 2 * horizontalPadding,
          width: hoverTopRight ? videoWidth * 1.9 : videoWidth,
          transform: hoverTopRight ? "" : "rotateY(180deg)",
          zIndex: hoverTopRight ? 900 : 100,
          backgroundColor: loadingColor,
        }}
        id="videoContainRRRRid"
        key={"videoContainRRRR"}
        onMouseEnter={() => setHoverTopRight(true)}
        onMouseLeave={() => setHoverTopRight(false)}
      >
        <video
          style={{
            ...videoStyle,
          }}
          playsInline
          ref={ref3}
          autoPlay
          key={"videoRRRR"}
          id="videoID3"
          controls
        />
      </div>
    </div>
  );
}
