import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import { Row } from "react-bootstrap";
import enterBid from "../sounds/220212__gameaudio__ping-bing.wav";
import leaveBid from "../sounds/496760__malle99__click-tick-2.mp3";

export function Bids({
  buffer,
  pageHeight,
  dotsState,
  placeBid,
  seatToNamesState,
  audio,
}) {
  let fontSize = `${0.04 * pageHeight}px`;

  let radius = pageHeight / 35;
  let margin = radius * 0.1;

  let bidsWidth = 13 * radius + margin * 26;
  let bidsHeight = radius + margin * 2;

  const [topNumber, setTopNumber] = useState(0);
  const [topNil, setTopNil] = useState(false);

  const [bottomNumber, setBottomNumber] = useState(0);
  const [bottomNil, setBottomNil] = useState(false);

  const dots = useRecoilValue(dotsState);

  const seatToNames = useRecoilValue(seatToNamesState);

  const defaultColor = "#b3ffb3";

  function playEnter() {
    if (audio.duration) {
      if (audio.duration !== 0.52244 && audio.currentTime < 0.05) {
        return;
      }
    }
    audio.src = enterBid;
    let playPromise = audio.play();
    if (playPromise !== undefined) {
      playPromise.then((_) => {}).catch((error) => {});
    }
  }

  function playExit() {
    if (audio.currentTime < 0.2) {
      return;
    }

    audio.src = leaveBid;

    let playPromise = audio.play();
    if (playPromise !== undefined) {
      playPromise.then((_) => {}).catch((error) => {});
    }
  }

  let drawTopCiclre = ({ k, color, i }) => {
    let padding = radius * 0.1;
    let numberFontSize = radius * 0.6;

    return (
      <div
        key={k + color + "bid"}
        style={{
          zIndex: 0,
          height: radius,
          width: radius,
          borderRadius: radius,
          margin: margin,
          fontSize: `${numberFontSize}px`,
          color:
            dots["topBids"][i + 1] !== defaultColor || i < topNumber
              ? "white"
              : "darkgreen",
          fontWeight: "bold",
          padding: `${padding}px`,
          backgroundColor:
            dots["topBids"][i + 1] === defaultColor &&
            i < topNumber &&
            dots["additionalTopBids"]
              ? dots["additionalTopBids"]
              : dots["topBids"][i + 1],
          textAlign: "center",
          transition: ".4s ease-in",
          cursor:
            dots["additionalTopBids"] && dots["topBids"][i + 1] === defaultColor
              ? "pointer"
              : "default",
          boxShadow:
            dots["topBids"][i + 1] === defaultColor &&
            i < topNumber &&
            dots["additionalTopBids"]
              ? `1px 1px 4px 2px #b3ffb3`
              : `1px 1px 5px 2px #4f5951`,
        }}
        onMouseEnter={() => {
          if (
            dots["additionalTopBids"] &&
            dots["topBids"][i + 1] === defaultColor
          ) {
            setTopNumber(i + 1);
            setTopNil(false);
            playEnter();
          }
        }}
        onMouseLeave={() => {
          if (
            dots["additionalTopBids"] &&
            dots["topBids"][i + 1] === defaultColor
          ) {
            setTopNumber(0);
            setTopNil(false);
            playExit();
          }
        }}
        onClick={() => {
          if (
            dots["additionalTopBids"] &&
            dots["topBids"][i + 1] === defaultColor
          ) {
            setTopNumber(0);
            setTopNil(false);
            placeBid(i + 1);
          }
        }}
      >
        {i + 1}
      </div>
    );
  };

  let drawBottomCiclre = ({ k, color, i }) => {
    let padding = radius * 0.1;
    let numberFontSize = radius * 0.6;
    return (
      <div
        key={k + color + "bid"}
        style={{
          zIndex: 0,
          height: radius,
          width: radius,
          borderRadius: radius,
          margin: margin,
          fontSize: `${numberFontSize}px`,
          color:
            dots["bottomBids"][i + 1] !== defaultColor || i < bottomNumber
              ? "white"
              : "darkgreen",
          fontWeight: "bold",
          padding: `${padding}px`,
          backgroundColor:
            dots["bottomBids"][i + 1] === defaultColor &&
            i < bottomNumber &&
            dots["additionalBottomBids"]
              ? dots["additionalBottomBids"]
              : dots["bottomBids"][i + 1],
          textAlign: "center",

          transition: ".4s ease-in",
          cursor:
            dots["additionalBottomBids"] &&
            dots["bottomBids"][i + 1] === defaultColor
              ? "pointer"
              : "default",
          boxShadow:
            dots["bottomBids"][i + 1] === defaultColor &&
            i < bottomNumber &&
            dots["additionalBottomBids"]
              ? `1px 1px 4px 2px #b3ffb3`
              : `1px 1px 5px 2px #4f5951`,
        }}
        onMouseEnter={() => {
          if (
            dots["additionalBottomBids"] &&
            dots["bottomBids"][i + 1] === defaultColor
          ) {
            setBottomNumber(i + 1);
            setBottomNil(false);
            playEnter();
          }
        }}
        onMouseLeave={() => {
          if (
            dots["additionalBottomBids"] &&
            dots["bottomBids"][i + 1] === defaultColor
          ) {
            setBottomNumber(0);
            setBottomNil(false);
            playExit();
          }
        }}
        onClick={() => {
          if (
            dots["additionalBottomBids"] &&
            dots["bottomBids"][i + 1] === defaultColor
          ) {
            setBottomNumber(0);
            setBottomNil(false);
            placeBid(i + 1);
          }
        }}
      >
        {i + 1}
      </div>
    );
  };

  if (
    dots &&
    !dots.firstProgress &&
    dots["topBids"] &&
    seatToNames &&
    seatToNames.full
  ) {
    return (
      <div
        style={{
          zIndex: 0,
        }}
      >
        <Row
          style={{
            top: `${pageHeight / 2 - bidsHeight * 3}px`,
            left: `${buffer + pageHeight / 2 - bidsWidth / 2}px`,
            height: bidsHeight,
            position: "absolute",
            display: "flex",
            flexDirection: "row",
            zIndex: -1000,
          }}
        >
          <div
            style={{
              color:
                dots["topBids"][0] === defaultColor &&
                topNil === true &&
                dots["additionalTopBids"]
                  ? dots["additionalTopBids"]
                  : dots["topBids"][0],
              transition: ".4s ease-in",
              cursor:
                dots["additionalTopBids"] && dots["topBids"][0] === defaultColor
                  ? "pointer"
                  : "default",
              zIndex: -1000,
              fontSize: fontSize,
              fontWeight: "bold",
              marginTop: `${-0.012 * pageHeight}px`,
              marginRight: `${0.012 * pageHeight}px`,
              textShadow:
                dots["topBids"][0] === defaultColor &&
                topNil === true &&
                dots["additionalTopBids"]
                  ? "#b3ffb3 1px 1px 5px"
                  : "#4f5951 1px 1px 2px",
            }}
            onMouseEnter={() => {
              if (
                dots["additionalTopBids"] &&
                dots["topBids"][0] === defaultColor
              ) {
                setTopNumber(0);
                setTopNil(true);
                playEnter();
              }
            }}
            onMouseLeave={() => {
              if (
                dots["additionalTopBids"] &&
                dots["topBids"][0] === defaultColor
              ) {
                setTopNumber(0);
                setTopNil(false);
                playExit();
              }
            }}
            onClick={() => {
              if (
                dots["additionalTopBids"] &&
                dots["topBids"][0] === defaultColor
              ) {
                placeBid(0);
              }
            }}
          >
            Ø
          </div>

          {Array(13)
            .fill(0)
            .map((_, k) => {
              return drawTopCiclre({
                k: "topWhiteBids" + k,
                color: "white",
                i: k,
              });
            })}
        </Row>
        {dots["topBidArrow"] && (
          <div>
            <div
              style={{
                top: `${pageHeight * 0.3}px`,
                left: `${buffer + pageHeight / 2 - bidsWidth / 2}px`,
                height: bidsHeight,
                width: bidsWidth,
                position: "absolute",
                // display: "flex",
                // flexDirection: "row",
                zIndex: 100,
                color: dots["topBidArrow"],
                cursor: "default",
                fontSize: `${0.05 * pageHeight}px`,
                // backgroundColor: "white",
                textAlign: "center",
                fontWeight: "bold",
                textShadow: "#b3ffb3 1px 1px 2px",
                transform: "scaleX(2)",
              }}
            >
              ↓
            </div>

            <div
              style={{
                top: `${pageHeight * 0.25}px`,
                left: `${buffer + pageHeight / 2 - bidsWidth / 2}px`,
                height: bidsHeight,
                width: bidsWidth,
                position: "absolute",
                // display: "flex",
                // flexDirection: "row",
                zIndex: 100,
                color: dots["topBidArrow"],
                cursor: "default",
                fontSize: `${
                  dots["bidTextBN"] ? 0.025 * pageHeight : 0.035 * pageHeight
                }px`,

                // backgroundColor: "white",
                textAlign: "center",
                // fontWeight: "bold",
                textShadow: "#b3ffb3 0px 0px 1px",
                // transform: "scaleX(2)",
              }}
            >
              {dots["bidTextBN"] ? dots["bidTextBN"] : dots["bidText"]}
            </div>
          </div>
        )}

        {dots["bottomBidArrow"] && (
          <div>
            <div
              style={{
                top: `${pageHeight * 0.545}px`,
                left: `${buffer + pageHeight / 2 - bidsWidth / 2}px`,
                height: bidsHeight,
                width: bidsWidth,
                position: "absolute",
                // display: "flex",
                // flexDirection: "row",
                zIndex: 100,
                color: dots["bottomBidArrow"],
                cursor: "default",
                fontSize: `${0.05 * pageHeight}px`,
                // backgroundColor: "white",
                textAlign: "center",
                fontWeight: "bold",
                textShadow: "#b3ffb3 1px 1px 2px",
                transform: "scaleX(2)",
              }}
            >
              ↑
            </div>
            <div
              style={{
                top: `${pageHeight * 0.625}px`,
                left: `${buffer + pageHeight / 2 - bidsWidth / 2}px`,
                height: bidsHeight,
                width: bidsWidth,
                position: "absolute",
                // display: "flex",
                // flexDirection: "row",
                zIndex: 100,
                color: dots["bottomBidArrow"],
                cursor: "default",
                fontSize: `${
                  dots["bidTextBN"] ? 0.025 * pageHeight : 0.035 * pageHeight
                }px`, // backgroundColor: "white",
                textAlign: "center",
                // fontWeight: "bold",
                textShadow: "#b3ffb3 0px 0px 1px",
              }}
            >
              {dots["bidTextBN"] ? dots["bidTextBN"] : dots["bidText"]}
            </div>
          </div>
        )}
        <Row
          style={{
            top: `${pageHeight / 2}px`,
            left: `${buffer + pageHeight / 2 - bidsWidth / 2}px`,
            height: bidsHeight,
            position: "absolute",
            display: "flex",
            flexDirection: "row",
            zIndex: -1000,
          }}
        >
          <div
            style={{
              color:
                dots["bottomBids"][0] === defaultColor &&
                bottomNil === true &&
                dots["additionalBottomBids"]
                  ? dots["additionalBottomBids"]
                  : dots["bottomBids"][0],
              transition: ".4s ease-in",
              cursor:
                dots["additionalBottomBids"] &&
                dots["topBids"][0] === defaultColor
                  ? "pointer"
                  : "default",
              zIndex: -1000,
              fontSize: fontSize,
              marginRight: `${0.012 * pageHeight}px`,
              fontWeight: "bold",
              marginTop: `${-0.012 * pageHeight}px`,
              textShadow:
                dots["bottomBids"][0] === defaultColor &&
                bottomNil === true &&
                dots["additionalBottomBids"]
                  ? "#b3ffb3 1px 1px 5px"
                  : "#4f5951 1px 1px 2px",
            }}
            onMouseEnter={() => {
              if (
                dots["additionalBottomBids"] &&
                dots["topBids"][0] === defaultColor
              ) {
                playEnter();
                setBottomNumber(0);
                setBottomNil(true);
              }
            }}
            onMouseLeave={() => {
              if (
                dots["additionalBottomBids"] &&
                dots["topBids"][0] === defaultColor
              ) {
                playExit();
                setBottomNumber(0);
                setBottomNil(false);
              }
            }}
            onClick={() => {
              if (
                dots["additionalBottomBids"] &&
                dots["bottomBids"][0] === defaultColor
              ) {
                placeBid(0);
              }
            }}
          >
            Ø
          </div>

          {Array(13)
            .fill(0)
            .map((_, k) => {
              return drawBottomCiclre({
                k: "bottomWhiteBids" + k,
                color: "white",
                i: k,
              });
            })}
        </Row>
      </div>
    );
  }
  return <div></div>;
}
